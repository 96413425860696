/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-07-04 11:23:30
 */
import axios from 'axios';
import { oidcPasswordFlowSetting } from '@/config/oidc-password-flow';
import { errMessage, request } from '@/utils/remote-request';

export class LoginData {
  username: string | undefined | null;

  password: string | undefined | null;
}

export interface ILoginResult {
  access_token: string | null | undefined;
  expires_in: number; // unit s
  token_type: string | null | undefined;
  refresh_token: string | null | undefined;
}

export class AuthService {
  /**
   *oidc 密码流登录
   *
   * @param {LoginData} data
   * @memberof UserService
   */
  static async signIn(data: LoginData): Promise<ILoginResult> {
    const res = await axios.request({
      method: 'POST',
      url: '/connect/token',
      baseURL: import.meta.env.VITE_APP_PROXY_TARGET,
      data: { ...oidcPasswordFlowSetting, ...data, refresh_token: '' },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      responseType: 'json',
    });

    return res.data;
  }

  static async signOut(): Promise<unknown> {
    return request({
      method: 'POST',
      url: '/connect/logout',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      data: null,
      responseType: 'json',
    });
  }

  static async refreshToken(refresh_token: string): Promise<ILoginResult> {
    oidcPasswordFlowSetting.grant_type = 'refresh_token';
    oidcPasswordFlowSetting.scope = 'api';
    oidcPasswordFlowSetting.refresh_token = refresh_token;
    const res = await axios.request({
      method: 'POST',
      url: '/connect/token',
      data: oidcPasswordFlowSetting,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      responseType: 'json',
    }).catch(() => {
      errMessage();
    });

    return res?.data as unknown as Promise<ILoginResult>;
  }
}
