/*
 * @Description: 当前访问路由持久化
 * @Author: luckymiaow
 * @Date: 2022-09-02 10:08:45
 * @LastEditors: Please set LastEditors
 */

import { onMounted, watch } from 'vue';
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { useRoute } from 'vue-router';

export default () => {
  const route = useRoute();

  const KEY = 'ROUTE_LOCAL';

  const setCurrentRoute = (paramter: RouteLocationNormalizedLoaded) => {
    const t = {
      path: paramter.path,
      name: paramter.name,
      fullPath: paramter.fullPath,
      query: paramter.query,
      meta: paramter.meta,
    };
    sessionStorage.setItem(KEY, JSON.stringify(t));
  };

  // const getCurrentRoute = () => {
  //   const r = sessionStorage.getItem(KEY);
  //   return r ? JSON.parse(r) : null;
  // };

  watch(
    () => route.path,
    () => {
      setCurrentRoute(route);
    },
  );

  const initCurrentRoute = () => {
    if (window.history.length > 1) {
      // const r = getCurrentRoute();
      // if (r) router.push(r);
    }
  };

  onMounted(() => {
    initCurrentRoute();
  });
};
