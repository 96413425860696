/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-05-25 09:51:26
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/config',
    component: DefaultLayout,
    meta: { title: '系统配置', icon: 'SettingOutlined', order: 99 },
    redirect: '/config/base',

    children: [
      {
        path: 'base',
        name: 'Base',
        component: () => import('@/views/config/base/index.vue'),
        meta: { title: '基础信息', icon: 'team-outlined' },
      },
      {
        path: 'sms',
        name: 'Sms',
        component: () => import('@/views/config/sms/index.vue'),
        meta: { title: '短信配置', icon: 'team-outlined' },
      },
      {
        path: 'wechat',
        name: 'Wechat',
        component: () => import('@/views/config/wechat/index.vue'),
        meta: { title: '微信配置', icon: 'schedule-outlined' },
      },
    ],
  },
];

export default routesMap;
export const order = 99;
