/*
 * @Author: xinner
 * @Date: 2022-03-25 09:56:02
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-07 16:56:09
 * @Description:
 */
import { createApp } from 'vue';
import 'virtual:uno.css';
import '@/assets/default.less';
import App from './App.vue';
import { setupDirective } from './directives';
import { setupRouter } from './router';
import '@/utils/remote-request';
import stores from '@/stores';

const app = createApp(App);

app.use(stores);

setupDirective(app);

setupRouter(app).then(router => router.isReady().then(() => app.mount('#app')));
