/*
 * @Description:
 * @Author: 景 彡
 * @Date: 2023-07-12 11:33:34
 * @LastEditors: 景 彡
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/course-management',
    component: DefaultLayout,
    meta: { title: '课程管理', icon: 'book-outlined' },
    children: [
      {
        path: '',
        component: () => import('@/views/course-management/index.vue'),
        name: 'courseManagement',
        meta: { title: '课程管理', icon: 'book-outlined' },
      },
      {
        path: 'course-classification',
        component: () => import('@/views/course-classification/index.vue'),
        name: 'courseClassification',
        meta: { title: '课程分类' },
      },
    ],
  },
  {
    path: '/online-learning-management',
    component: DefaultLayout,
    meta: { title: '在线学习管理', icon: 'book-outlined' },
    children: [
      {
        path: '',
        component: () => import('@/views/online-learning-management/index.vue'),
        name: 'OnlineLearningManagement',
        meta: { title: '在线课程管理', icon: 'book-outlined' },
      },
      {
        path: 'autonomous-learning',
        component: () => import('@/views/online-learning-management/autonomousLearning.vue'),
        name: 'AutonomousLearning',
        meta: { title: '自主学习推送', icon: 'read-outlined' },
      },
      {
        path: 'online-learning-classification',
        component: () => import('@/views/online-learning-management/classification.vue'),
        name: 'OnlineLearningCourseClassification',
        meta: { title: '课程分类' },
      },
    ],
  },

  {
    path: '/registration-batch',
    component: DefaultLayout,
    meta: { title: '促进师认证管理', icon: 'schedule-outlined' },
    children: [
      {
        path: '',
        component: () => import('@/views/facilitator-list/index.vue'),
        name: 'Promoter',
        meta: { title: '促进师列表', icon: 'bars-outlined' },
      },
      {
        path: 'management',
        component: () => import('@/views/registration-batch/index.vue'),
        name: 'RegistrationBatch',
        meta: { title: '认证计划列表', icon: 'appstore-add-outlined' },
      },
      {
        path: 'schedule-list',
        component: () => import('@/views/certification-progress/index.vue'),
        name: 'ScheduleList',
        meta: { title: '认证进度列表', icon: 'schedule-outlined' },
      },

      {
        path: 'certification-review',
        component: () => import('@/views/certification-review/index.vue'),
        name: 'certificationReview',
        meta: { title: '报名材料审核', icon: 'safety-certificate-outlined' },
      },
      // {
      //   path: 'online-learning',
      //   component: () => import('@/views/online-learning/index.vue'),
      //   name: 'OnlineLearning',
      //   meta: { title: '在线学习记录', icon: 'highlight-outlined' },
      // },
      {
        path: 'exam-management',
        component: () => import('@/views/exam-management/index.vue'),
        name: 'ExamManagement',
        meta: { title: '考试记录', icon: 'container-outlined' },
      },
      {
        path: 'mockExam-manage',
        component: () => import('@/views/mockexam-manage/index.vue'),
        name: 'mockExamManage',
        meta: { title: '模拟考试记录', icon: 'aim-outlined' },
      },
      // {
      //   path: 'offline-practice',
      //   component: () => import('@/views/offline-practice/index.vue'),
      //   name: 'OfflinePractice',
      //   meta: { title: '线下实操(中高)', icon: 'profile-outlined' },
      // },
      // {
      //   path: 'review',
      //   component: () => import('@/views/exam-management/index.vue'),
      //   name: 'ExamManagementReview',
      //   meta: { title: '评审', icon: 'container-outlined' },
      // },
      {
        path: 'certificate-management',
        component: () => import('@/views/certificate-management/index.vue'),
        name: 'certificateManagement',
        meta: { title: '证书管理', icon: 'verified-outlined' },
      },
      {
        path: 'integral-management',
        component: () => import('@/views/integral-management/index.vue'),
        name: 'integralManagement',
        meta: { title: '从业积分记录', icon: 'sketch-outlined' },
      },
    ],
  },

];

export default routesMap;
