/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-28 10:39:27
 */
import { ConfigProvider } from 'ant-design-vue';
import { defineStore } from 'pinia';
import { appSetting } from '@/config/theme';
import type { IAppState } from '@/types/theme';

export const useAppStore = defineStore('app', {
  state: (): IAppState => appSetting,
  getters: {
    appCurrentSetting(state: IAppState): IAppState {
      return { ...state };
    },
  },
  actions: {
    /**
     *更新配置
     *
     * @param {Partial<IAppState>} partial
     */
    updateSetting(partial: Partial<IAppState>) {
      this.$patch(partial);

      if ('primaryColor' in partial) {
        ConfigProvider.config({
          theme: {
            primaryColor: partial.primaryColor,
          },
        });
      }
    },
    /**
     *重置配置
     *
     */
    resetSetting() {
      this.$reset();
    },
  },
});
