/*
 * @Description: ^_^'
 * @Author: sharebravery
 * @Date: 2023-06-05 15:53:32
 */
import { defineStore } from 'pinia';
import type { ILoginResult, LoginData } from '@/api/services/auth';
import { AuthService } from '@/api/services/auth';

export interface IAuthState extends ILoginResult {
  signIn_timestamp?: number;
}

export const useAuthStore = defineStore('auth', {
  state: (): IAuthState => ({
    access_token: null,
    expires_in: 3600,
    token_type: null,
    refresh_token: null,
    signIn_timestamp: Date.now(),
  }),
  getters: {
    getToken(): string | undefined | null {
      if (this.expires_in < Date.now() / 1000)
        return this.access_token; // 令牌未过期
      else
        return null; // 令牌已过期
    },
  },
  actions: {
    async signIn(data: LoginData): Promise<ILoginResult | null> {
      try {
        const authentication = await AuthService.signIn(data);

        this.signIn_timestamp = Date.now();

        this.setData(authentication);

        return authentication;
      }
      catch (error: any) {
        throw new Error(error.response.data.error_description || error.message || error);
      }
    },
    async refreshToken() {
      if (!this.refresh_token || !this.signIn_timestamp)
        return;

      const signInDuration = Date.now() - this.signIn_timestamp;
      if (signInDuration >= (this.expires_in * 1000 / 2)) {
        const authentication = await AuthService.refreshToken(this.refresh_token);

        this.setData(authentication);

        this.signIn_timestamp = Date.now();
      }
    },
    signOut() {
      AuthService.signOut();
      this.clear();
    },
    setData(data: IAuthState) {
      Object.assign(this.$state, data);
    },
    async setToken(token: string) {
      this.access_token = token;
      await this.getInfo();
    },

    clear() {
      this.$reset();
      // 保留的键值对列表
      const preservedKeys = ['app', 'draggedElementPosition'];

      localStorage.removeItem('user');

      // 遍历localStorage，移除不需要保留的键值对
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);

        // 如果键不在保留的键值对列表中，则移除
        if (key && !preservedKeys.includes(key))
          localStorage.removeItem(key);
      }
    },
  },
});
