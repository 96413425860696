/* eslint-disable prettier/prettier */
import type { AxiosResponse } from "axios";
import type { RequestOptions } from "./options";
import { apiOptions, ApiAction } from "./options";
import {
  AliyunAccessKey,
  ApiResult,
  AuditStatus,
  Authentication,
  AuthenticationBase,
  AuthenticationResult,
  AuthenticationType,
  AuthenticationUserView,
  AuthenticationView,
  BaseInfo,
  BaseUserRequestLog,
  BasicInformationTypeDescription,
  BatchSendSms,
  CaptchaData,
  CategoryType,
  Certificate,
  CertificateBase,
  CertificateErrorViewModel,
  CertificateLevel,
  CertificatePageViewModel,
  CertificateRenewal,
  CertificateRenewalEditModel,
  CertificateRenewalPageViewModel,
  CertificateRenewalReview,
  CertificateRenewalViewModel,
  CertificateViewModel,
  Contact,
  ContactEditModel,
  Course,
  CourseCategory,
  CourseEditModel,
  CourseEvaluation,
  CourseEvaluationEditModel,
  CourseEvaluationViewModel,
  CourseRegistration,
  CourseRegistrationEditModel,
  CourseRegistrationViewModel,
  CourseViewModel,
  CreateCertificateEditModel,
  CurrentUserPasswordChangeEditModel,
  DeletedFileInfo,
  DifficultyLevel,
  EfCoreResourcePermission,
  EmbedFileInfo,
  Exam,
  ExamAdminView,
  ExamAnswer,
  ExamBase,
  ExamPageView,
  ExamPaper,
  ExamPaperEditModel,
  ExamPaperStatus,
  ExamQuestion,
  ExamQuestionEditModel,
  ExamQuestionExamView,
  ExamQuestionHistory,
  ExamQuestionHistoryView,
  ExamQuestionViewModel,
  ExamViewModel,
  FacilitatorPageView,
  FacilitatorView,
  FileAttribution,
  FileType,
  HandsOnEvaluation,
  HandsOnEvaluationBase,
  HandsOnEvaluationViewModel,
  HomeView,
  IActionResult,
  ILimitedResource,
  IPagedEnumerable,
  IPermissionStoreCapacities,
  IResourceMetadata,
  IResourcePermission,
  IVersioned,
  IdCardPhotoInfo,
  IdentityRole,
  IdentityUser,
  IdentityUserLoginLog,
  IdentityUserRole,
  Information,
  InformationCategory,
  InformationCategoryEditModel,
  InformationCategoryRef,
  InformationCategoryView,
  InformationEditModel,
  InformationSortBy,
  InformationStatus,
  Integral,
  IntegralState,
  IntegralType,
  InvalidModelApiResult,
  KnowledgePoint,
  KnowledgePointEditModel,
  KnowledgePointViewModel,
  LeavingMessage,
  LeavingMessageState,
  LimitedPermissionNode,
  LimitedResourceNode,
  LoginResultLog,
  LongIdNameViewModel,
  MockExam,
  MockExamAnswerView,
  Notice,
  NoticeBase,
  NoticeEditModel,
  NoticeStatus,
  NoticeType,
  OLCourse,
  OLCourseCategory,
  OLCourseEditModel,
  OLCoursePageViewUser,
  OLCourseRecord,
  OLCourseStatus,
  OLCourseVideo,
  OLCourseVideoPush,
  OLCourseVideoPushEditModel,
  OLCourseVideoPushLearningRecord,
  OLCourseVideoPushLearningRecordEditModel,
  OLCourseVideoPushRecord,
  OLCourseVideoPushView,
  OLCourseVideoRecord,
  OLCourseVideoRecordEditModel,
  OLCourseViewModel,
  OLCourseViewUser,
  PackedApiResult,
  PasswordCheckConfig,
  PasswordVerifyResult,
  PermissionType,
  PublicityEndEdit,
  PublicityStatus,
  QuestionOption,
  QuestionOptionEditModel,
  QuestionOptionRecord,
  QuestionOptionViewModel,
  QuestionRecord,
  QuestionStatus,
  QuestionType,
  RequestType,
  ResourceGrant,
  ResourceMetadata,
  ResourcePermission,
  ResourceType,
  ResponseType,
  ResultMessage,
  Role,
  SendObjectGroupEditModel,
  SendObjectGroupItemEditModel,
  SimulateUserViewModel,
  SmsAuditStatus,
  SmsContactType,
  SmsKeysConfig,
  SmsSendPersonnel,
  SmsSendResult,
  SmsSendRoles,
  SmsSendStatus,
  SmsSendTask,
  SmsSendTaskEditModel,
  SmsSendTaskViewModel,
  SmsSendType,
  SmsTemplate,
  SmsTemplateEditModel,
  StudentAnswerViewModel,
  StudentExamViewModel,
  SystemInfo,
  TemplateType,
  UploadFileInfo,
  User,
  UserAuthenticationDocuments,
  UserAuthenticationDocumentsBase,
  UserAuthenticationRecord,
  UserAuthenticationStatus,
  UserCreateModel,
  UserDocumentsPageView,
  UserDocumentsView,
  UserEditModel,
  UserExpirationEditModel,
  UserInfoView,
  UserLoginLog,
  UserNotice,
  UserNoticeViewModel,
  UserPasswordChangeEditModel,
  UserRecordPageView,
  UserRegisterEditModel,
  UserRequestLog,
  UserRole,
  UserRoleViewModel,
  UserViewModel,
  VerificationType,
  WeChatApiKeyEntity,
  WeChatCallbackToken,
  WeChatSceneType,
  WeChatSyncModel,
  WeChatUserInfoViewModel,
} from "../models";

export * from "./options";

function callApiAsync<TData, TResult>(
  options: RequestOptions<TData>
): Promise<TResult> {
  return apiOptions.ajaxRequestAsync(options) as Promise<TResult>;
}
function callSourceApiAsync<TData, TResult>(
  options: RequestOptions<TData>
): Promise<AxiosResponse<TResult>> {
  return apiOptions.ajaxRequestAsync(options) as Promise<
    AxiosResponse<TResult>
  >;
}
async function callPackedApiAsync<TData, TResult>(
  options: RequestOptions<TData>
): Promise<TResult> {
  const result = (await callApiAsync<TData, PackedApiResult<TResult>>(
    options
  )) as PackedApiResult<TResult>;
  if (!result.success) {
    apiOptions.throwOnApiFaulted(result);
  }
  return result.data!;
}
export class Authentications {
  /**
   * GET /api/Authentications/ExportFacilitators
   * 导出促进师列表
   */
  @ApiAction({
    id: "ExportFacilitators",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "导出促进师列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ExportFacilitators_GetAsync(
    params:
      | { level?: CertificateLevel; companyName?: string; keywords?: string }
      | null
      | undefined
  ): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/ExportFacilitators`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindFacilitatorsByUserId
   * 促进师列表详情
   */
  @ApiAction({
    id: "FindFacilitatorsByUserId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "促进师列表详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindFacilitatorsByUserId_GetAsync(params: {
    /* 促进师列表可获取 */ userId: number;
  }): Promise<CertificateViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindFacilitatorsByUserId`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindAuthenticationById
   * 根据id查找认证计划详情
   */
  @ApiAction({
    id: "FindAuthenticationById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据id查找认证计划详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindAuthenticationById_GetAsync(params: {
    id: number;
  }): Promise<Authentication> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindAuthenticationById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindAuthenticationByUser
   * 用户查找认证计划列表
   */
  @ApiAction({
    id: "FindAuthenticationByUser",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户查找认证计划列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindAuthenticationByUser_GetAsync(
    params:
      | {
          keyword?: string;
          level?: CertificateLevel;
          isEnd?: boolean;
          /* 是否显示已经获取证书的批次 */ isHas?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<AuthenticationUserView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindAuthenticationByUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindPublicity
   * 获取该批次的公示名单
   */
  @ApiAction({
    id: "FindPublicity",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取该批次的公示名单",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindPublicity_GetAsync(params: {
    authenticationId: number;
  }): Promise<User[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindPublicity`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/StartPublicity
   * 开始公示
   */
  @ApiAction({
    id: "StartPublicity",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "开始公示",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async StartPublicity_GetAsync(params: {
    authenticationId: number;
    /* 重新开始公示 */ again?: boolean;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/StartPublicity`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/NoPassPublicity
   * 公示不通过
   */
  @ApiAction({
    id: "NoPassPublicity",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "公示不通过",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async NoPassPublicity_GetAsync(params: {
    recordId: number;
    reason?: string;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/NoPassPublicity`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindOneBySelf
   * 用户查看自己的报名资料情况
   */
  @ApiAction({
    id: "FindOneBySelf",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户查看自己的报名资料情况",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindOneBySelf_GetAsync(
    params:
      | { authenticetionId?: number; userRecordId?: number }
      | null
      | undefined
  ): Promise<UserDocumentsView> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindOneBySelf`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindUserDocument
   * 管理查看用户报名资料列表
   */
  @ApiAction({
    id: "FindUserDocument",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "管理查看用户报名资料列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindUserDocument_GetAsync(
    params:
      | {
          authenticationId?: number;
          name?: string;
          isPassed?: boolean;
          /* 待审核 */ isToBeReviewed?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<UserDocumentsPageView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindUserDocument`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindUserDocumentById
   * 根据id查找报名资料详情
   */
  @ApiAction({
    id: "FindUserDocumentById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据id查找报名资料详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindUserDocumentById_GetAsync(params: {
    id: number;
  }): Promise<UserDocumentsView> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindUserDocumentById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/SaveDocumentsForReview
   * 管理审核用户资料
   */
  @ApiAction({
    id: "SaveDocumentsForReview",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "管理审核用户资料",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SaveDocumentsForReview_GetAsync(params: {
    id: number;
    isPassed: boolean;
    remark?: string;
    /* 版本号 */ version: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/SaveDocumentsForReview`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/ExportSchedule
   * 导出认证进度列表
   */
  @ApiAction({
    id: "ExportSchedule",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "导出认证进度列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ExportSchedule_GetAsync(
    params:
      | {
          authenticationId?: number;
          status?: UserAuthenticationStatus;
          level?: CertificateLevel;
          name?: string;
        }
      | null
      | undefined
  ): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/ExportSchedule`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindViewById
   * 认证进度详情
   */
  @ApiAction({
    id: "FindViewById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "认证进度详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindViewById_GetAsync(params: {
    authenticationId: number;
  }): Promise<UserAuthenticationRecord> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindViewById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindHandsOnEvaluationById
   * 根据id查找线下操作记录
   */
  @ApiAction({
    id: "FindHandsOnEvaluationById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据id查找线下操作记录",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindHandsOnEvaluationById_GetAsync(params: {
    id: number;
  }): Promise<HandsOnEvaluationViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindHandsOnEvaluationById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/FindOneByUARId
   * 根据用户报名记录查找线下操作记录
   */
  @ApiAction({
    id: "FindOneByUARId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据用户报名记录查找线下操作记录",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindOneByUarId_GetAsync(params: {
    /* 用户报名记录id */ userARId: number;
  }): Promise<HandsOnEvaluationViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/FindOneByUARId`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Authentications/HomeView
   * 移动端首页获取统计数据
   */
  @ApiAction({
    id: "HomeView",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "移动端首页获取统计数据",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async HomeView_GetAsync(): Promise<HomeView> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Authentications/HomeView`,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/FindFacilitators
   * 促进师列表
   */
  @ApiAction({
    id: "FindFacilitators",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "促进师列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindFacilitators_PostAsync(
    params:
      | {
          level?: CertificateLevel;
          companyName?: string;
          keywords?: string;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<FacilitatorPageView>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/FindFacilitators`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/CreateAuthentication
   * 新增认证计划
   */
  @ApiAction({
    id: "CreateAuthentication",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "新增认证计划",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateAuthentication_PostAsync(
    data: AuthenticationBase
  ): Promise<AuthenticationView> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/CreateAuthentication`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/DeleteAuthenticationById
   * 删除认证计划
   */
  @ApiAction({
    id: "DeleteAuthenticationById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除认证计划",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async DeleteAuthenticationById_PostAsync(params: {
    id: number;
  }): Promise<number> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/DeleteAuthenticationById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/EditAuthentication
   * 修改认证计划
   */
  @ApiAction({
    id: "EditAuthentication",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "修改认证计划",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EditAuthentication_PostAsync(
    data: AuthenticationBase
  ): Promise<AuthenticationView> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/EditAuthentication`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/FindAuthentication
   * 认证计划列表
   */
  @ApiAction({
    id: "FindAuthentication",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "认证计划列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindAuthentication_PostAsync(
    params:
      | {
          keyword?: string;
          level?: CertificateLevel;
          /* 已结束的批次用户无法查看 */ isEnd?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<AuthenticationView>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/FindAuthentication`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/FindAuthenticationViewList
   * 认证计划列表List
   */
  @ApiAction({
    id: "FindAuthenticationViewList",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "认证计划列表List",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindAuthenticationViewList_PostAsync(
    params:
      | { keyword?: string; level?: CertificateLevel; isEnd?: boolean }
      | null
      | undefined
  ): Promise<AuthenticationView[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/FindAuthenticationViewList`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/EndPublicity
   * 结束公示
   */
  @ApiAction({
    id: "EndPublicity",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "结束公示",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EndPublicity_PostAsync(
    params: { authenticationId: number },
    data: PublicityEndEdit[]
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/EndPublicity`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/CreateDocuments
   * 用户报名、上传报名资料
   */
  @ApiAction({
    id: "CreateDocuments",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户报名、上传报名资料",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateDocuments_PostAsync(
    params: { authenticationId: number },
    data: UserAuthenticationDocumentsBase
  ): Promise<UserAuthenticationDocuments> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/CreateDocuments`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/EditDocuments
   * 用户修改报名资料
   */
  @ApiAction({
    id: "EditDocuments",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户修改报名资料",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EditDocuments_PostAsync(
    params: { authenticationId: number },
    data: UserAuthenticationDocumentsBase
  ): Promise<UserAuthenticationDocuments> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/EditDocuments`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/FindView
   * 认证进度列表
   */
  @ApiAction({
    id: "FindView",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "认证进度列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindView_PostAsync(
    params:
      | {
          authenticationId?: number;
          status?: UserAuthenticationStatus;
          /* 等级 */ level?: CertificateLevel;
          /* 用户昵称 */ name?: string;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<UserRecordPageView>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/FindView`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Authentications/SetHandsOnEvaluation
   * 管理设置线下操作记录
   */
  @ApiAction({
    id: "SetHandsOnEvaluation",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "管理设置线下操作记录",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetHandsOnEvaluation_PostAsync(
    params: { /* 个人认证记录id */ userARId: number },
    data: HandsOnEvaluationBase
  ): Promise<HandsOnEvaluation> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Authentications/SetHandsOnEvaluation`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class BaseInfoManage {
  /**
   * GET /api/BaseInfoManage/GetSystemBaseType
   * 系统基础类型  默认不允许
   */ static async GetSystemBaseTypeAsync(): Promise<
    BasicInformationTypeDescription[]
  > {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/BaseInfoManage/GetSystemBaseType`,
      responseType: "json",
    });
  }
  /**
   * GET /api/BaseInfoManage/GetTypeDescription
   * 获取参数允许的类型
   */ static async GetTypeDescriptionAsync(): Promise<
    BasicInformationTypeDescription[]
  > {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/BaseInfoManage/GetTypeDescription`,
      responseType: "json",
    });
  }
  /**
   * GET /api/BaseInfoManage/VerifyData
   * 验证数据是否正确
   */ static async VerifyData_GetAsync(
    params:
      | { /* 数据类型 */ type?: string; /* 数据 */ data?: string }
      | null
      | undefined
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/BaseInfoManage/VerifyData`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/BaseInfoManage/VerifyType
   * 验证类型是否正确
   */ static async VerifyType_GetAsync(
    params: { /* 数据类型 */ type?: string } | null | undefined
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/BaseInfoManage/VerifyType`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/BaseInfoManage/GetPaged
   * 获取所有基础信息(隐藏除外)
   */ static async GetPagedAsync(
    params: { limit?: number; offset?: number } | null | undefined
  ): Promise<IPagedEnumerable<BaseInfo>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/BaseInfoManage/GetPaged`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/BaseInfoManage/GetInfo
   * 基础信息内容
   */ static async GetInfoAsync(
    params: { key?: string } | null | undefined
  ): Promise<BaseInfo> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/BaseInfoManage/GetInfo`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/BaseInfoManage/AddTypeDescription
   * 添加类型
   */ static async AddTypeDescription_PostAsync(
    data: BasicInformationTypeDescription
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/BaseInfoManage/AddTypeDescription`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/BaseInfoManage/Save
   * 基础信息内容
   */ static async Save_PostAsync(data: BaseInfo): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/BaseInfoManage/Save`,
      data,
      responseType: "json",
    });
  }
}
export class Captchas {
  /**
   * GET /api/Captchas/Get
   */ static async GetAsync(): Promise<CaptchaData> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Captchas/Get`,
      responseType: "json",
    });
  }
  /**
   * GET /api/Captchas/Validate
   */ static async Validate_GetAsync(
    params: { captchaId?: string; code?: string } | null | undefined
  ): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Captchas/Validate`,
      params,
      responseType: "json",
    });
  }
}
export class CertificateRenewals {
  /**
   * GET /api/CertificateRenewals/Find
   * 管理端查看证书续签审核列表
   */
  @ApiAction({
    id: "CertificateRenewals.Find",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "管理端查看证书续签审核列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Find_GetAsync(
    params:
      | {
          /* 证书id */ certificateId?: number;
          /* 证书编号 */ number?: string;
          /* 身份证 */ userName?: string;
          /* 姓名 */ name?: string;
          /* 审核状态，0待审核 1 通过审核 2不通过审核 */ state?: number;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<CertificateRenewalPageViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CertificateRenewals/Find`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/CertificateRenewals/PendingReviewCount
   * 获取待审数量
   */
  @ApiAction({
    id: "CertificateRenewals.PendingReviewCount",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取待审数量",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async PendingReviewCount_GetAsync(): Promise<number> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CertificateRenewals/PendingReviewCount`,
      responseType: "json",
    });
  }
  /**
   * GET /api/CertificateRenewals/FindViewById
   * 根据id查询审核详情
   */
  @ApiAction({
    id: "CertificateRenewals.FindViewById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据id查询审核详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindViewById_GetAsync(params: {
    id: number;
  }): Promise<CertificateRenewalViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CertificateRenewals/FindViewById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/CertificateRenewals/Application
   * 申请续签
   */
  @ApiAction({
    id: "CertificateRenewals.Application",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "申请续签",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Application_PostAsync(
    data: CertificateRenewalEditModel
  ): Promise<CertificateRenewalViewModel> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CertificateRenewals/Application`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/CertificateRenewals/Review
   * 审核
   */
  @ApiAction({
    id: "CertificateRenewals.Review",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "审核",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Review_PostAsync(
    data: CertificateRenewalReview
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CertificateRenewals/Review`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/CertificateRenewals/BatchReviewByCertificateIds
   */
  @ApiAction({
    id: "CertificateRenewals.BatchReviewByCertificateIds",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "BatchReviewByCertificateIds",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async BatchReviewByCertificateIds_PostAsync(
    data: number[]
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CertificateRenewals/BatchReviewByCertificateIds`,
      data,
      responseType: "json",
    });
  }
}
export class Certificates {
  /**
   * GET /api/Certificates/GetSelfCertificateAsyn
   * 用户查看自己证书
   */
  @ApiAction({
    id: "Certificates.GetSelfCertificateAsyn",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户查看自己证书",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetSelfCertificateAsynAsync(
    params:
      | { name?: string; number?: string; remove?: boolean }
      | null
      | undefined
  ): Promise<CertificateViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/GetSelfCertificateAsyn`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Certificates/Authentication
   * 验证证书真伪
   */
  @ApiAction({
    id: "Certificates.Authentication",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "验证证书真伪",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Authentication_GetAsync(
    params: { idCard?: string; number?: string } | null | undefined
  ): Promise<AuthenticationResult> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/Authentication`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Certificates/FindById
   * 获取证书详情
   */
  @ApiAction({
    id: "Certificates.FindById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取证书详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindById_GetAsync(params: { id: number }): Promise<Certificate> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/FindById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Certificates/FindViewById
   * 管理端获取证书详情信息
   */
  @ApiAction({
    id: "Certificates.FindViewById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "管理端获取证书详情信息",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindViewById_GetAsync(params: {
    id: number;
  }): Promise<CertificateViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/FindViewById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Certificates/Find
   * 获取证书生成错误log列表
   */
  @ApiAction({
    id: "Certificates.Find",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取证书生成错误log列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Find_GetAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<CertificateErrorViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/Find`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Certificates/Regenerate
   * 根据log重新生成证书
   */
  @ApiAction({
    id: "Certificates.Regenerate",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据log重新生成证书",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Regenerate_GetAsync(params: {
    errorId: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/Regenerate`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Certificates/GenerateByRecordId
   * 重新生成证书
   */
  @ApiAction({
    id: "Certificates.GenerateByRecordId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "重新生成证书",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GenerateByRecordId_GetAsync(params: {
    recordId: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Certificates/GenerateByRecordId`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Certificates/FindAsyn
   * 管理端查找用户证书列表
   */
  @ApiAction({
    id: "Certificates.FindAsyn",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "管理端查找用户证书列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindAsyn_PostAsync(
    params:
      | {
          userName?: string;
          number?: string;
          level?: CertificateLevel;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<CertificatePageViewModel>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Certificates/FindAsyn`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Certificates/CreateCertificate
   * 创建证书
   */
  @ApiAction({
    id: "Certificates.CreateCertificate",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "创建证书",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateCertificate_PostAsync(
    data: CreateCertificateEditModel
  ): Promise<Certificate> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Certificates/CreateCertificate`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Certificates/DeleteById
   * 删除证书
   */
  @ApiAction({
    id: "Certificates.DeleteById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除证书",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async DeleteById_PostAsync(params: { id: number }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Certificates/DeleteById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Certificates/ExportCertificatesByNames
   * 导出证书
   */
  @ApiAction({
    id: "Certificates.ExportCertificatesByNames",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "导出证书",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ExportCertificatesByNames_PostAsync(
    data: string[]
  ): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Certificates/ExportCertificatesByNames`,
      data,
      responseType: "json",
    });
  }
}
export class Contacts {
  /**
   * GET /api/Contacts/FindOneById
   * 根据id查找最新数据
   */
  @ApiAction({
    id: "Contacts.FindOneById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据id查找最新数据",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindOneById_GetAsync(params: { id: number }): Promise<Contact> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Contacts/FindOneById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Contacts/GetGroupAll
   * 获取所有分类
   */
  @ApiAction({
    id: "Contacts.GetGroupAll",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取所有分类",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetGroupAllAsync(): Promise<string[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Contacts/GetGroupAll`,
      responseType: "json",
    });
  }
  /**
   * POST /api/Contacts/CreateContact
   * 创建一条记录
   */
  @ApiAction({
    id: "Contacts.CreateContact",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "创建一条记录",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateContact_PostAsync(
    data: ContactEditModel
  ): Promise<Contact> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Contacts/CreateContact`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Contacts/ModifyContact
   * 编辑一条记录
   */
  @ApiAction({
    id: "Contacts.ModifyContact",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "编辑一条记录",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ModifyContact_PostAsync(
    data: ContactEditModel
  ): Promise<Contact> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Contacts/ModifyContact`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Contacts/RemoveByIds
   * 删除记录
   */
  @ApiAction({
    id: "Contacts.RemoveByIds",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除记录",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async RemoveByIds_PostAsync(data: number[]): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Contacts/RemoveByIds`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Contacts/Find
   * 条件查询
   */
  @ApiAction({
    id: "Contacts.Find",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "条件查询",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Find_PostAsync(
    params:
      | {
          name?: string;
          phone?: string;
          /* 分组 */ group?: string;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<Contact>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Contacts/Find`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Contacts/ExportContact
   * 导出通讯录 (可选定导出)
   */
  @ApiAction({
    id: "Contacts.ExportContact",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "导出通讯录 (可选定导出)",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ExportContact_PostAsync(
    params: { group?: string } | null | undefined,
    data: number[]
  ): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Contacts/ExportContact`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class Crypto {
  /**
   * GET /api/Crypto/GetShortToken
   */ static async GetShortTokenAsync(): Promise<string> {
    return callApiAsync({
      method: "GET",
      url: `/api/Crypto/GetShortToken`,
      responseType: "json",
    });
  }
  /**
   * GET /api/Crypto/GetRsaPublicKey
   */ static async GetRsaPublicKeyAsync(): Promise<string> {
    return callApiAsync({
      method: "GET",
      url: `/api/Crypto/GetRsaPublicKey`,
      responseType: "json",
    });
  }
}
export class CurrentUser {
  /**
   * GET /api/CurrentUser/Me
   * 用户信息
   */ static async Me_GetAsync(): Promise<UserViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/Me`,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/CurrentUserLoginLogList
   * 获取当前用户的登录日志
   */ static async CurrentUserLoginLogList_GetAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<UserLoginLog>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/CurrentUserLoginLogList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/CurrentUserRequestLogList
   * 获取当前用户的请求日志
   */ static async CurrentUserRequestLogList_GetAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<UserRequestLog>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/CurrentUserRequestLogList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/GetBareheadedPhoto
   * 获取用户的免冠照
   */ static async GetBareheadedPhotoAsync(): Promise<EmbedFileInfo> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/GetBareheadedPhoto`,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/GetIdCardPhoto
   * 获取用户的身份证照片
   */ static async GetIdCardPhotoAsync(): Promise<IdCardPhotoInfo> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/GetIdCardPhoto`,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/GetUserIinfo
   * 获取用户个人资料
   */ static async GetUserIinfoAsync(): Promise<UserInfoView> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/GetUserIinfo`,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/DetectAuth
   * 获取实名认证接口
   */ static async DetectAuth_GetAsync(
    params: { returnUrl?: string } | null | undefined
  ): Promise<string> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/DetectAuth`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/CurrentUser/GetUserDetectAuthInfo
   * 获取实名认证结果
   */ static async GetUserDetectAuthInfoAsync(
    params: { biz?: string } | null | undefined
  ): Promise<ResultMessage> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/CurrentUser/GetUserDetectAuthInfo`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/CurrentUser/EditUserModel
   * 修改用户信息
   */ static async EditUserModel_PostAsync(
    data: UserEditModel
  ): Promise<UserViewModel> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CurrentUser/EditUserModel`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/CurrentUser/EditUserPassword
   * 修改用户密码
   */ static async EditUserPassword_PostAsync(
    data: CurrentUserPasswordChangeEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CurrentUser/EditUserPassword`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/CurrentUser/EditUserPasswordByCode
   * 短信修改密码
   */ static async EditUserPasswordByCode_PostAsync(
    params:
      | { code?: string; password?: string; phoneNumber?: string }
      | null
      | undefined
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CurrentUser/EditUserPasswordByCode`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/CurrentUser/SetUserBareheadedPhoto
   * 设置用户免冠照
   */ static async SetUserBareheadedPhoto_PostAsync(params: {
    fileId: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CurrentUser/SetUserBareheadedPhoto`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/CurrentUser/SetUserIdCardPhoto
   * 设置用户身份证照片
   */ static async SetUserIdCardPhoto_PostAsync(params: {
    /* 身份证正面 */ idCardFront: number;
    /* 身份证反面 */ idCardBack: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CurrentUser/SetUserIdCardPhoto`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/CurrentUser/SetUserInfo
   * 设置用户个人资料
   */ static async SetUserInfo_PostAsync(
    data: UserInfoView
  ): Promise<UserInfoView> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/CurrentUser/SetUserInfo`,
      data,
      responseType: "json",
    });
  }
}
export class DataMigration {
  /**
   * GET /api/DataMigration/FixInfoFile
   */ static async FixInfoFile_GetAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/DataMigration/FixInfoFile`,
      responseType: "json",
    });
  }
  /**
   * GET /api/DataMigration/FixInfoCoverFile
   */ static async FixInfoCoverFile_GetAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/DataMigration/FixInfoCoverFile`,
      responseType: "json",
    });
  }
  /**
   * GET /api/DataMigration/FixStringIsNull
   */ static async FixStringIsNull_GetAsync(): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/DataMigration/FixStringIsNull`,
      responseType: "json",
    });
  }
  /**
   * GET /api/DataMigration/DeleDataIsNull
   */ static async DeleDataIsNull_GetAsync(): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/DataMigration/DeleDataIsNull`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/ImportPlan
   * 迁移认证计划表
   */ static async ImportPlan_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/ImportPlan`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/ImportAuthentication
   * 迁移认证计划进度表
   */ static async ImportAuthentication_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/ImportAuthentication`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/ImportUser
   * 迁移用户表
   */ static async ImportUser_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/ImportUser`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/ImportCret
   * 迁移证书表
   */ static async ImportCret_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/ImportCret`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/ImportFile
   * 迁移文件
   */ static async ImportFile_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/ImportFile`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/Importassess_fileFile
   * 迁移文件
   */ static async ImportassessFileFile_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/Importassess_fileFile`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/Importfacilitator_certFile
   * 迁移证书文件
   */ static async ImportfacilitatorCertFile_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/Importfacilitator_certFile`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/ImportFacilitator_Enroll_PhotosFile
   * 迁移旧系统登录注册文件
   */ static async ImportFacilitatorEnrollPhotosFile_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/ImportFacilitator_Enroll_PhotosFile`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/Importoauth_info
   * 迁移外部登录信息
   */ static async ImportoauthInfo_PostAsync(): Promise<IActionResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/Importoauth_info`,
      responseType: "json",
    });
  }
  /**
   * POST /api/DataMigration/FindIdCard
   */ static async FindIdCard_PostAsync(data: string[]): Promise<string[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/DataMigration/FindIdCard`,
      data,
      responseType: "json",
    });
  }
}
export class Discipline {
  /**
   * GET /api/Discipline/GetCourseCategoryList
   * 获取分类列表
   */ static async GetCourseCategoryListAsync(
    params: { name?: string } | null | undefined
  ): Promise<CourseCategory[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetCourseCategoryList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetCourseCategoryPage
   * 获取分类分页
   */ static async GetCourseCategoryPageAsync(
    params:
      | { name?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<CourseCategory>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetCourseCategoryPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetCourseCategory
   * 获取分类
   */ static async GetCourseCategoryAsync(params: {
    id: number;
  }): Promise<CourseCategory> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetCourseCategory`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetCourseList
   * 获取课程列表
   */ static async GetCourseListAsync(
    params:
      | {
          name?: string;
          category?: number;
          address?: string;
          status?: AuditStatus;
          teacherId?: number;
          auditorId?: number;
          start?: Date;
        }
      | null
      | undefined
  ): Promise<CourseViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetCourseList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetCoursePage
   * 获取课程分页
   */ static async GetCoursePageAsync(
    params:
      | {
          name?: string;
          category?: number;
          address?: string;
          status?: AuditStatus;
          teacherId?: number;
          auditorId?: number;
          start?: Date;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<CourseViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetCoursePage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetCourse
   * 获取课程
   */ static async GetCourseAsync(params: {
    id: number;
  }): Promise<CourseViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetRegistrationByCourse
   * 获取课程下的所有签到
   */ static async GetRegistrationByCourseAsync(params: {
    courseId: number;
  }): Promise<CourseRegistrationViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetRegistrationByCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/GetEvaluationByCourse
   * 获取课程下的所有评价
   */ static async GetEvaluationByCourseAsync(params: {
    courseId: number;
  }): Promise<CourseEvaluationViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/GetEvaluationByCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/FindIntegral
   * 从业积分记录
   */ static async FindIntegral_GetAsync(
    params:
      | {
          /* 用户名或身份证 */ username?: string;
          userId?: number;
          state?: IntegralState;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<Integral>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/FindIntegral`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Discipline/FindIntegralByUser
   * h5从业积分记录
   */ static async FindIntegralByUser_GetAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<Integral>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Discipline/FindIntegralByUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/CreateCategory
   * 创建分类
   */ static async CreateCategory_PostAsync(
    data: CourseCategory
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/CreateCategory`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/EditCategory
   * 编辑分类
   */ static async EditCategory_PostAsync(data: CourseCategory): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/EditCategory`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/DeleteCourseCategory
   * 删除分类
   */ static async DeleteCourseCategory_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/DeleteCourseCategory`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/Create
   * 申请课程
   */ static async Create_PostAsync(data: CourseEditModel): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/Create`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/Edit
   * 修改课程
   */ static async Edit_PostAsync(data: CourseEditModel): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/Edit`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/EditByAdmin
   * 修改课程(管理员)
   */ static async EditByAdmin_PostAsync(
    params: { /* 是否回退到申请中这个状态 */ isGoBack: boolean },
    data: CourseEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/EditByAdmin`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/AuditCourse
   * 审核课程
   */ static async AuditCourse_PostAsync(params: {
    /* 课程Id */ courseId: number;
    /* 是否通过 */ status: boolean;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/AuditCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/DeleteCourse
   * 删除课程
   */ static async DeleteCourse_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/DeleteCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/DeleteCourseByAdmin
   * 删除课程(管理员)
   */ static async DeleteCourseByAdmin_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/DeleteCourseByAdmin`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/AddEvaluation
   * 学员课程评价
   */ static async AddEvaluation_PostAsync(
    data: CourseEvaluationEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/AddEvaluation`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/Registration
   * 课程签到
   */ static async Registration_PostAsync(
    data: CourseRegistrationEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/Registration`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/ApplyIntegral
   * h5 积分申请
   */ static async ApplyIntegral_PostAsync(params: {
    score: number;
    remark?: string;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/ApplyIntegral`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Discipline/ExamineApplyIntegral
   * 管理端审核
   */ static async ExamineApplyIntegral_PostAsync(params: {
    id: number;
    state?: IntegralState;
    notRemark?: string;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Discipline/ExamineApplyIntegral`,
      params,
      responseType: "json",
    });
  }
}
export class ExamManage {
  /**
   * GET /api/ExamManage/QueryExam
   * 管理端查询考试列表
   */ static async QueryExam_GetAsync(
    params:
      | {
          authenticationId?: number;
          isEnd?: boolean;
          isDescending?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<ExamPageView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamManage/QueryExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamManage/FindOneByIdForAdmin
   * 管理端查询考试详情
   */ static async FindOneByIdForAdmin_GetAsync(params: {
    examId: number;
  }): Promise<ExamAdminView> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamManage/FindOneByIdForAdmin`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamManage/QueryExamPage
   * 考生查询考试列表
   */ static async QueryExamPage_GetAsync(
    params:
      | { title?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<ExamViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamManage/QueryExamPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamManage/StartExam
   * 开始考试
   */ static async StartExam_GetAsync(params: {
    authenticationId: number;
  }): Promise<StudentExamViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamManage/StartExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamManage/FindOneByIdForStudent
   * 考生查看考试结果详情
   */ static async FindOneByIdForStudent_GetAsync(
    params: { authenticationId?: number; examId?: number } | null | undefined
  ): Promise<StudentAnswerViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamManage/FindOneByIdForStudent`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamManage/SubmitExam
   * 交卷
   */ static async SubmitExam_GetAsync(params: {
    examId: number;
  }): Promise<number> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamManage/SubmitExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamManage/DeleteExam
   * 删除考试
   */ static async DeleteExam_PostAsync(params: { id: number }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamManage/DeleteExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamManage/UpdateExamAnswers
   * 更新答题卡
   */ static async UpdateExamAnswers_PostAsync(
    params: { examId: number },
    data: ExamAnswer[]
  ): Promise<ExamAnswer[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamManage/UpdateExamAnswers`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class ExamPaperManage {
  /**
   * GET /api/ExamPaperManage/QueryPaper
   * 查询试卷
   */ static async QueryPaper_GetAsync(
    params: { title?: string; desc?: string } | null | undefined
  ): Promise<ExamPaperEditModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamPaperManage/QueryPaper`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamPaperManage/GetAllPaper
   * 查询所有试卷
   */ static async GetAllPaperAsync(): Promise<ExamPaperEditModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamPaperManage/GetAllPaper`,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamPaperManage/GetAllPaperPage
   * 查询所有试卷 分页
   */ static async GetAllPaperPageAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<ExamPaperEditModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamPaperManage/GetAllPaperPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamPaperManage/QueryPaperPage
   * 查询试卷 分页
   */ static async QueryPaperPage_GetAsync(
    params:
      | { title?: string; desc?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<ExamPaperEditModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamPaperManage/QueryPaperPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamPaperManage/GetPaperById
   * 获取试卷，根据Id
   */ static async GetPaperByIdAsync(params: {
    id: number;
  }): Promise<ExamPaperEditModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamPaperManage/GetPaperById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamPaperManage/QueryQuestions
   * 查询试题
   */ static async QueryQuestions_GetAsync(params: {
    paperId: number;
  }): Promise<ExamQuestionViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamPaperManage/QueryQuestions`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamPaperManage/AddPaper
   * 添加试卷
   */ static async AddPaper_PostAsync(data: ExamPaperEditModel): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamPaperManage/AddPaper`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamPaperManage/UpdatePaper
   * 更新试卷
   */ static async UpdatePaper_PostAsync(
    data: ExamPaperEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamPaperManage/UpdatePaper`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamPaperManage/DeletePaper
   * 删除试卷
   */ static async DeletePaper_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamPaperManage/DeletePaper`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamPaperManage/UpdateQuestions
   * 更新试题
   */ static async UpdateQuestions_PostAsync(
    params: { paperId: number },
    data: number[]
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamPaperManage/UpdateQuestions`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class ExamQuestionManage {
  /**
   * GET /api/ExamQuestionManage/QueryQuestionsPage
   * 查询试题
   */ static async QueryQuestionsPage_GetAsync(
    params:
      | {
          title?: string;
          pointId?: number;
          isDescending?: boolean;
          type?: QuestionType;
          level?: DifficultyLevel;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<ExamQuestionViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamQuestionManage/QueryQuestionsPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/ExamQuestionManage/GetQuestionById
   * 获取试题详情
   */ static async GetQuestionByIdAsync(params: {
    id: number;
  }): Promise<ExamQuestionViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/ExamQuestionManage/GetQuestionById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamQuestionManage/AddQuestion
   * 添加试题
   */ static async AddQuestion_PostAsync(
    data: ExamQuestionEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamQuestionManage/AddQuestion`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamQuestionManage/UpdateQuestion
   * 更新试题
   */ static async UpdateQuestion_PostAsync(
    data: ExamQuestionEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamQuestionManage/UpdateQuestion`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamQuestionManage/DeleteQuestion
   * 删除试题
   */ static async DeleteQuestion_PostAsync(data: number[]): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamQuestionManage/DeleteQuestion`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamQuestionManage/UpdateKnowPoint
   * 更新知识点
   */ static async UpdateKnowPoint_PostAsync(
    params: { questionId: number },
    data: number[]
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamQuestionManage/UpdateKnowPoint`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/ExamQuestionManage/ImportQuestions
   * 导入试题
   */ static async ImportQuestions_PostAsync(
    data: FormData | null | undefined
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/ExamQuestionManage/ImportQuestions`,
      headers: { "Content-Type": "multipart/form-data" },
      data,
      responseType: "json",
    });
  }
}
export class FileManage {
  /**
   * GET /api/FileManage/GetFiles
   * 获取文件列表
   */ static async GetFilesAsync(
    params:
      | {
          type?: FileType;
          attribution?: FileAttribution;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<UploadFileInfo>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/FileManage/GetFiles`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/FileManage/GetRemoveFiles
   * 获取回收站文件列表
   */ static async GetRemoveFilesAsync(
    params:
      | {
          type?: FileType;
          attribution?: FileAttribution;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<DeletedFileInfo>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/FileManage/GetRemoveFiles`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/FileManage/UploadPortalFile
   * 文件上传-门户站点文章用
   */ static async UploadPortalFile_PostAsync(
    data: FormData | null | undefined
  ): Promise<UploadFileInfo[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/FileManage/UploadPortalFile`,
      headers: { "Content-Type": "multipart/form-data" },
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/FileManage/UploadCertifiedFile
   * 文件上传
   */ static async UploadCertifiedFile_PostAsync(
    data: FormData | null | undefined
  ): Promise<UploadFileInfo[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/FileManage/UploadCertifiedFile`,
      headers: { "Content-Type": "multipart/form-data" },
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/FileManage/RemoveFile
   * 删除文件
   */ static async RemoveFile_PostAsync(params: {
    id: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/FileManage/RemoveFile`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/FileManage/RemoveCompletelyFile
   * 彻底删除文件
   */ static async RemoveCompletelyFile_PostAsync(params: {
    id: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/FileManage/RemoveCompletelyFile`,
      params,
      responseType: "json",
    });
  }
}
export class HomePortal {
  /**
   * GET /api/HomePortal/GeCategoryPaged
   * 获取分类列表
   */ static async GeCategoryPaged_GetAsync(
    params:
      | {
          type?: CategoryType;
          name?: string;
          menuShow?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<InformationCategoryView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/HomePortal/GeCategoryPaged`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/HomePortal/GetByInformationId
   * 获取文章最新版本
   */ static async GetByInformationIdAsync(params: {
    /* informationId */ informationId: string;
  }): Promise<Information> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/HomePortal/GetByInformationId`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/HomePortal/GetInformation
   * 查找文章
   */ static async GetInformation_PostAsync(
    params:
      | {
          title?: string;
          content?: string;
          keyword?: string;
          categoryName?: string;
          categoryType?: CategoryType;
          isTop?: boolean;
          author?: string;
          startTime?: Date;
          endTime?: Date;
          sortBy?: InformationSortBy;
          isDescending?: boolean;
          isCover?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined,
    data: number[]
  ): Promise<IPagedEnumerable<Information>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/HomePortal/GetInformation`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/HomePortal/Add
   * 添加留言
   */ static async Add_PostAsync(data: LeavingMessage): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/HomePortal/Add`,
      data,
      responseType: "json",
    });
  }
}
export class InformationCategories {
  /**
   * GET /api/InformationCategories/GetOneById
   * 根据ID查找
   */
  @ApiAction({
    id: "InformationCategories.GetOneById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据ID查找",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetOneByIdAsync(params: {
    id: number;
  }): Promise<InformationCategory> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/InformationCategories/GetOneById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/InformationCategories/GetRefById
   * 根据ID查找Ref
   */
  @ApiAction({
    id: "InformationCategories.GetRefById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据ID查找Ref",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetRefByIdAsync(params: {
    id: number;
  }): Promise<InformationCategoryRef> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/InformationCategories/GetRefById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/InformationCategories/GetRefByType
   * 获取分类列表Ref
   */
  @ApiAction({
    id: "InformationCategories.GetRefByType",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取分类列表Ref",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetRefByTypeAsync(
    params: { type?: CategoryType; isAdmin?: boolean } | null | undefined
  ): Promise<InformationCategoryRef[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/InformationCategories/GetRefByType`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/InformationCategories/GeCategoryPaged
   * 获取分类列表
   */
  @ApiAction({
    id: "InformationCategories.GeCategoryPaged",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取分类列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GeCategoryPaged_GetAsync(
    params:
      | {
          type?: CategoryType;
          name?: string;
          menuShow?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<InformationCategoryView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/InformationCategories/GeCategoryPaged`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/InformationCategories/CreateInformationCategory
   * 创建分类
   */
  @ApiAction({
    id: "InformationCategories.CreateInformationCategory",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "创建分类",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateInformationCategory_PostAsync(
    data: InformationCategoryEditModel
  ): Promise<InformationCategory> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/InformationCategories/CreateInformationCategory`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/InformationCategories/ModifyInformationCategory
   * 编辑分类
   */
  @ApiAction({
    id: "InformationCategories.ModifyInformationCategory",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "编辑分类",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ModifyInformationCategory_PostAsync(
    data: InformationCategoryEditModel
  ): Promise<InformationCategory> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/InformationCategories/ModifyInformationCategory`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/InformationCategories/DeleteInformationCategory
   * 删除分类
   */
  @ApiAction({
    id: "InformationCategories.DeleteInformationCategory",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除分类",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async DeleteInformationCategory_PostAsync(
    data: number[]
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/InformationCategories/DeleteInformationCategory`,
      data,
      responseType: "json",
    });
  }
}
export class Informations {
  /**
   * GET /api/Informations/GetByInformationId
   * 获取文章最新版本
   */
  @ApiAction({
    id: "Informations.GetByInformationId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取文章最新版本",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetByInformationIdAsync(params: {
    /* informationId */ informationId: string;
  }): Promise<Information> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Informations/GetByInformationId`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Informations/GetInformationHistory
   * 获取文章历史版本
   */
  @ApiAction({
    id: "Informations.GetInformationHistory",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取文章历史版本",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetInformationHistoryAsync(params: {
    informationId: string;
  }): Promise<Information[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Informations/GetInformationHistory`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/CreateInformation
   * 创建文章
   */
  @ApiAction({
    id: "Informations.CreateInformation",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "创建文章",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateInformation_PostAsync(
    data: InformationEditModel
  ): Promise<Information> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/CreateInformation`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/WeChatIsSyncByMsgIds
   * 根据msgIds查询公众号文章是否同步
   */
  @ApiAction({
    id: "Informations.WeChatIsSyncByMsgIds",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "根据msgIds查询公众号文章是否同步",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async WeChatIsSyncByMsgIds_PostAsync(
    data: string[]
  ): Promise<Information[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/WeChatIsSyncByMsgIds`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/WeChatSync
   * 同步微信文章
   */
  @ApiAction({
    id: "Informations.WeChatSync",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "同步微信文章",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async WeChatSync_PostAsync(
    params: { status?: InformationStatus; categoryId: number },
    data: WeChatSyncModel[]
  ): Promise<Information[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/WeChatSync`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/ModifyInformation
   * 编辑文章
   */
  @ApiAction({
    id: "Informations.ModifyInformation",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "编辑文章",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ModifyInformation_PostAsync(
    data: InformationEditModel
  ): Promise<Information> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/ModifyInformation`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/DeleteByInformationId
   * 删除文章
   */
  @ApiAction({
    id: "Informations.DeleteByInformationId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除文章",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async DeleteByInformationId_PostAsync(params: {
    informationId: string;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/DeleteByInformationId`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/RemoveCompletelyByInformationId
   * 彻底删除回收站文章
   */
  @ApiAction({
    id: "Informations.RemoveCompletelyByInformationId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "彻底删除回收站文章",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async RemoveCompletelyByInformationId_PostAsync(params: {
    informationId: string;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/RemoveCompletelyByInformationId`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/SetStatusByInformationId
   * 设置文章状态
   */
  @ApiAction({
    id: "Informations.SetStatusByInformationId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "设置文章状态",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetStatusByInformationId_PostAsync(params: {
    informationId: string;
    status?: InformationStatus;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/SetStatusByInformationId`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/SetInformationTop
   * 更改置顶状态
   */
  @ApiAction({
    id: "Informations.SetInformationTop",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "更改置顶状态",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetInformationTop_PostAsync(params: {
    informationId: string;
    top: boolean;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/SetInformationTop`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Informations/GetInformation
   * 查找文章
   */
  @ApiAction({
    id: "Informations",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "文章管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetInformation_PostAsync(
    params:
      | {
          title?: string;
          content?: string;
          keyword?: string;
          categoryName?: string;
          categoryType?: CategoryType;
          isTop?: boolean;
          author?: string;
          startTime?: Date;
          endTime?: Date;
          status?: InformationStatus;
          sortBy?: InformationSortBy;
          isDescending?: boolean;
          isCover?: boolean;
          tag?: string;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined,
    data: number[]
  ): Promise<IPagedEnumerable<Information>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Informations/GetInformation`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class KnowPointManage {
  /**
   * GET /api/KnowPointManage/GetAllKnowledgePoints
   * 获取所有的知识点
   */ static async GetAllKnowledgePointsAsync(): Promise<
    KnowledgePointViewModel[]
  > {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/KnowPointManage/GetAllKnowledgePoints`,
      responseType: "json",
    });
  }
  /**
   * GET /api/KnowPointManage/GetKnowledgePointById
   * 获取知识点的详细信息
   */ static async GetKnowledgePointByIdAsync(params: {
    id: number;
  }): Promise<KnowledgePointViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/KnowPointManage/GetKnowledgePointById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/KnowPointManage/AddKnowledgePoint
   * 添加知识点
   */ static async AddKnowledgePoint_PostAsync(
    data: KnowledgePointViewModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/KnowPointManage/AddKnowledgePoint`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/KnowPointManage/UpdateKnowledgePoint
   * 更新知识点
   */ static async UpdateKnowledgePoint_PostAsync(
    data: KnowledgePointViewModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/KnowPointManage/UpdateKnowledgePoint`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/KnowPointManage/DeleteKnowledgePoint
   * 删除知识点
   */ static async DeleteKnowledgePoint_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/KnowPointManage/DeleteKnowledgePoint`,
      params,
      responseType: "json",
    });
  }
}
export class LeavingMessages {
  /**
   * GET /api/LeavingMessages/Find
   * 查询留言
   */
  @ApiAction({
    id: "LeavingMessage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "文章管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Find_GetAsync(
    params:
      | {
          keyWord?: string;
          state?: LeavingMessageState;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<LeavingMessage>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/LeavingMessages/Find`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/LeavingMessages/SetState
   */
  @ApiAction({
    id: "LeavingMessage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "文章管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetState_PostAsync(params: {
    id: number;
    state?: LeavingMessageState;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/LeavingMessages/SetState`,
      params,
      responseType: "json",
    });
  }
}
export class Limits {
  /**
   * GET /api/Limits/GetStoreCapacities
   */
  @ApiAction({
    id: "Limits.GetStoreCapacities",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "GetStoreCapacities",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetStoreCapacitiesAsync(): Promise<IPermissionStoreCapacities> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/GetStoreCapacities`,
      responseType: "json",
    });
  }
  /**
   * GET /api/Limits/List
   */ static async List_GetAsync(): Promise<ILimitedResource[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/List`,
      responseType: "json",
    });
  }
  /**
   * GET /api/Limits/Tree
   */ static async Tree_GetAsync(): Promise<LimitedResourceNode[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/Tree`,
      responseType: "json",
    });
  }
  /**
   * GET /api/Limits/GetByRolename
   */
  @ApiAction({
    id: "Limits.GetByRolename",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "GetByRolename",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetByRolenameAsync(
    params: { roleName?: string } | null | undefined
  ): Promise<IResourcePermission> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/GetByRolename`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Limits/GetByUserId
   */
  @ApiAction({
    id: "Limits.GetByUserId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "GetByUserId",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetByUserIdAsync(
    params: { userId?: string } | null | undefined
  ): Promise<IResourcePermission> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/GetByUserId`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Limits/Permissions
   */ static async Permissions_GetAsync(): Promise<
    IVersioned<LimitedPermissionNode[]>
  > {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/Permissions`,
      responseType: "json",
    });
  }
  /**
   * GET /api/Limits/GetMetadata
   */
  @ApiAction({
    id: "Limits.GetMetadata",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "GetMetadata",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetMetadataAsync(
    params: { id?: string } | null | undefined
  ): Promise<IResourceMetadata> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Limits/GetMetadata`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Limits/SetByRolename
   */
  @ApiAction({
    id: "Limits.SetByRolename",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "SetByRolename",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetByRolename_PostAsync(
    params: { roleName?: string } | null | undefined,
    data: ResourcePermission
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Limits/SetByRolename`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Limits/SetByUserId
   */
  @ApiAction({
    id: "Limits.SetByUserId",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "SetByUserId",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetByUserId_PostAsync(
    params: { userId?: string } | null | undefined,
    data: ResourcePermission
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Limits/SetByUserId`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Limits/SetMetadata
   */
  @ApiAction({
    id: "Limits.SetMetadata",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "SetMetadata",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetMetadata_PostAsync(
    params: { id?: string } | null | undefined,
    data: ResourceMetadata
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Limits/SetMetadata`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class MockExamManage {
  /**
   * GET /api/MockExamManage/QueryMockExam
   * 管理端查询模拟考试列表
   */ static async QueryMockExam_GetAsync(
    params:
      | {
          authenticationId?: number;
          isEnd?: boolean;
          isDescending?: boolean;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<ExamPageView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/QueryMockExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/MockExamManage/FindOneByIdForAdmin
   * 管理端查询模拟考试详情
   */ static async FindOneByIdForAdmin_GetAsync(params: {
    examId: number;
  }): Promise<ExamAdminView> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/FindOneByIdForAdmin`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/MockExamManage/FindByUser
   * 获取模拟考列表
   */
  @ApiAction({
    id: "FindByUser",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取模拟考列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindByUser_GetAsync(
    params:
      | {
          keyword?: string;
          level?: CertificateLevel;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<AuthenticationUserView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/FindByUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/MockExamManage/StartMockExam
   * 开始模拟考试
   */
  @ApiAction({
    id: "StartMockExam",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "开始模拟考试",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async StartMockExam_GetAsync(params: {
    authenticationId: number;
  }): Promise<StudentExamViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/StartMockExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/MockExamManage/QueryMockExamPageList
   * 考生查询模拟考试 分页
   */
  @ApiAction({
    id: "QueryMockExamPageList",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "考生查询模拟考试 分页",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async QueryMockExamPageList_GetAsync(
    params:
      | {
          title?: string;
          authenticationId?: number;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<ExamViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/QueryMockExamPageList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/MockExamManage/GetMockExamPageRecord
   * 考生查看考试结果详情
   */
  @ApiAction({
    id: "GetMockExamPageRecord",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "考生查看考试结果详情",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetMockExamPageRecordAsync(params: {
    examId: number;
  }): Promise<StudentAnswerViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/GetMockExamPageRecord`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/MockExamManage/SubmitExam
   * 交卷
   */
  @ApiAction({
    id: "SubmitExam",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "交卷",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SubmitExam_GetAsync(params: {
    examId: number;
  }): Promise<number> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/MockExamManage/SubmitExam`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/MockExamManage/UpdateMockExamAnswers
   * 更新答题卡
   */
  @ApiAction({
    id: "UpdateMockExamAnswers",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "更新答题卡",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async UpdateMockExamAnswers_PostAsync(
    params: { examId: number },
    data: ExamAnswer[]
  ): Promise<MockExamAnswerView[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/MockExamManage/UpdateMockExamAnswers`,
      params,
      data,
      responseType: "json",
    });
  }
}
export class Notices {
  /**
   * GET /api/Notices/SendNotice
   * 发送通知
   */
  @ApiAction({
    id: "Notices.SendNotice",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "发送通知",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SendNotice_GetAsync(params: {
    noticeId: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Notices/SendNotice`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Notices/FindNotices
   * 通知列表
   */
  @ApiAction({
    id: "Notices.FindNotices",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "通知列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindNotices_GetAsync(
    params:
      | {
          keyword?: string;
          noticeType?: NoticeType;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<Notice>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Notices/FindNotices`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Notices/FindById
   * FindById
   */
  @ApiAction({
    id: "Notices.FindById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "FindById",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindById_GetAsync(params: { id: number }): Promise<Notice> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Notices/FindById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/Notices/Removes
   * 删除
   */
  @ApiAction({
    id: "Notices.Removes",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Removes_GetAsync(params: {
    id: number;
    /* Notice.Status == 已发送时，需要弹窗确实该值，提示用户是否删除已发送的通知 */ isRemoveUserNotice?: boolean;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Notices/Removes`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Notices/CreateNotice
   * 新建通知
   */
  @ApiAction({
    id: "Notices.CreateNotice",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "新建通知",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateNotice_PostAsync(
    params:
      | { /* 保存时选择，后台默认保存为草稿 */ status?: NoticeStatus }
      | null
      | undefined,
    data: NoticeEditModel
  ): Promise<Notice> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Notices/CreateNotice`,
      params,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Notices/ModifyNotice
   * 编辑通知
   */
  @ApiAction({
    id: "Notices.ModifyNotice",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "编辑通知",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ModifyNotice_PostAsync(data: NoticeEditModel): Promise<Notice> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Notices/ModifyNotice`,
      data,
      responseType: "json",
    });
  }
}
export class OnlineCourse {
  /**
   * GET /api/OnlineCourse/GetCourseList
   * 获取课程列表
   */ static async GetCourseListAsync(
    params:
      | { name?: string; category?: number; status?: OLCourseStatus }
      | null
      | undefined
  ): Promise<OLCourseViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCourseList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetCoursePage
   * 获取课程分页
   */ static async GetCoursePageAsync(
    params:
      | {
          name?: string;
          category?: number;
          status?: OLCourseStatus;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<OLCourseViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCoursePage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetCourseListByCurrentUser
   * 用户获取课程列表
   */ static async GetCourseListByCurrentUserAsync(
    params:
      | { name?: string; category?: number; status?: OLCourseStatus }
      | null
      | undefined
  ): Promise<OLCoursePageViewUser[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCourseListByCurrentUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetCourse
   * 获取课程
   */ static async GetCourseAsync(params: {
    id: number;
  }): Promise<OLCourseViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/FinOneById
   * 管理获取课程详情
   */ static async FinOneById_GetAsync(params: {
    id: number;
  }): Promise<OLCourse> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/FinOneById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/FinViewByUser
   * 用户获取课程详情
   */ static async FinViewByUser_GetAsync(params: {
    id: number;
    oLCourseRecordId: number;
  }): Promise<OLCourseViewUser> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/FinViewByUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetCourseCategoryList
   * 获取分类列表
   */ static async GetCourseCategoryListAsync(
    params: { name?: string } | null | undefined
  ): Promise<OLCourseCategory[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCourseCategoryList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetCourseCategoryPage
   * 获取分类分页
   */ static async GetCourseCategoryPageAsync(
    params:
      | { name?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<OLCourseCategory>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCourseCategoryPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetCourseCategory
   * 获取分类
   */ static async GetCourseCategoryAsync(params: {
    id: number;
  }): Promise<OLCourseCategory> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetCourseCategory`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetPushPage
   * 管理端获取推送自主学习课程
   */ static async GetPushPageAsync(
    params:
      | { title?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<OLCourseVideoPushView>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetPushPage`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetPushById
   * 根据推送id获取推送记录
   */ static async GetPushByIdAsync(params: {
    id: number;
    keyWord?: string;
    offset?: number;
    limit?: number;
  }): Promise<IPagedEnumerable<OLCourseVideoPushRecord>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetPushById`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetPushPageByUser
   * 手机端获取推送自主学习视频
   */ static async GetPushPageByUserAsync(
    params:
      | { title?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<OLCourseVideoPushRecord>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetPushPageByUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/OnlineCourse/GetUserPushById
   * 获取自主推送学习课程详情
   */ static async GetUserPushByIdAsync(params: {
    id: number;
  }): Promise<OLCourseVideoPushRecord> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/OnlineCourse/GetUserPushById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/Create
   * 新增课程
   */ static async Create_PostAsync(
    data: OLCourseEditModel
  ): Promise<OLCourse> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/Create`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/Edit
   * 修改课程
   */ static async Edit_PostAsync(data: OLCourseEditModel): Promise<OLCourse> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/Edit`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/UpdateVideoRecord
   * 更新观看进度
   */ static async UpdateVideoRecord_PostAsync(
    data: OLCourseVideoRecordEditModel
  ): Promise<OLCourseVideoRecord> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/UpdateVideoRecord`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/DeleteCourse
   * 删除课程
   */ static async DeleteCourse_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/DeleteCourse`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/CreateCategory
   * 创建分类
   */ static async CreateCategory_PostAsync(
    data: OLCourseCategory
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/CreateCategory`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/EditCategory
   * 编辑分类
   */ static async EditCategory_PostAsync(
    data: OLCourseCategory
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/EditCategory`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/DeleteCourseCategory
   * 删除分类
   */ static async DeleteCourseCategory_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/DeleteCourseCategory`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/SavePush
   * 自主学习推送
   */ static async SavePush_PostAsync(
    data: OLCourseVideoPushEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/SavePush`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/UpdateVideoPushRecordByUser
   * 更新观看进度
   */ static async UpdateVideoPushRecordByUser_PostAsync(
    data: OLCourseVideoPushLearningRecordEditModel
  ): Promise<OLCourseVideoPushLearningRecord> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/UpdateVideoPushRecordByUser`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/OnlineCourse/DeleteVideoPushByIds
   * 删除自主学习推送
   */ static async DeleteVideoPushByIds_PostAsync(
    data: number[]
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/OnlineCourse/DeleteVideoPushByIds`,
      data,
      responseType: "json",
    });
  }
}
export class Register {
  /**
   * GET /api/Register/GetPasswordConfig
   * 获取密码验证规则
   */ static async GetPasswordConfigAsync(): Promise<PasswordCheckConfig> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Register/GetPasswordConfig`,
      responseType: "json",
    });
  }
  /**
   * POST /api/Register/UserRegister
   * 用户注册
   */ static async UserRegister_PostAsync(
    data: UserRegisterEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Register/UserRegister`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/Register/CheckPassword
   * 检查密码
   */ static async CheckPassword_PostAsync(
    params: { password?: string } | null | undefined
  ): Promise<PasswordVerifyResult> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Register/CheckPassword`,
      params,
      responseType: "json",
    });
  }
}
export class RolesManage {
  /**
   * POST /api/RolesManage/RoleList
   * 角色列表
   */ static async RoleList_PostAsync(
    params:
      | { roleName?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<Role>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/RoleList`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/RolesManage/RoleAll
   * 角色列表  完整
   */ static async RoleAll_PostAsync(
    params: { roleName?: string } | null | undefined
  ): Promise<Role[]> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/RoleAll`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/RolesManage/SetUserRole
   * 设置用户角色
   */ static async SetUserRole_PostAsync(params: {
    userId: number;
    roleId: number;
    expirationTime?: Date;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/SetUserRole`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/RolesManage/RemoveUserRole
   * 删除用户角色
   */ static async RemoveUserRole_PostAsync(params: {
    userId: number;
    roleId: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/RemoveUserRole`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/RolesManage/CreateRole
   * 创建角色
   */ static async CreateRole_PostAsync(
    params: { roleName?: string } | null | undefined
  ): Promise<Role> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/CreateRole`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/RolesManage/Delete
   * 删除角色
   */ static async Delete_PostAsync(params: {
    roleId: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/Delete`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/RolesManage/ModifyRole
   * 修改客户端角色
   */ static async ModifyRole_PostAsync(params: {
    roleId: number;
    roleName?: string;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/RolesManage/ModifyRole`,
      params,
      responseType: "json",
    });
  }
}
export class Simulate {
  /**
   * GET /api/Simulate/GetSimulateStatus
   * 获取用户模拟状态
   */
  @ApiAction({
    id: "Simulate",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "模拟用户",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetSimulateStatusAsync(): Promise<SimulateUserViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/Simulate/GetSimulateStatus`,
      responseType: "json",
    });
  }
  /**
   * POST /api/Simulate/SimulateLogin
   * 模拟用户
   */
  @ApiAction({
    id: "Simulate",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "模拟用户",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SimulateLogin_PostAsync(params: {
    /* 需要模拟的用户ID */ userId: number;
  }): Promise<UserViewModel> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Simulate/SimulateLogin`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/Simulate/ExitSimulate
   * 退出模拟
   */
  @ApiAction({
    id: "Simulate",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "模拟用户",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async ExitSimulate_PostAsync(): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/Simulate/ExitSimulate`,
      responseType: "json",
    });
  }
}
export class SmsManage {
  /**
   * GET /api/SmsManage/GetSmsProviders
   * 获取该系统支持的短信提供商
   */ static async GetSmsProvidersAsync(): Promise<string[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetSmsProviders`,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetBaseConfig
   * 获取短信基础配置
   */ static async GetBaseConfigAsync(): Promise<SmsKeysConfig> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetBaseConfig`,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetAliyunConfig
   * 获取阿里云短信配置
   */ static async GetAliyunConfigAsync(): Promise<AliyunAccessKey> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetAliyunConfig`,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetPageBySmsTemplate
   * 短信模板列表
   */ static async GetPageBySmsTemplateAsync(
    params:
      | {
          name?: string;
          type?: TemplateType;
          status?: SmsAuditStatus;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<SmsTemplate>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetPageBySmsTemplate`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetTemplateView
   * 短信模板视图
   */ static async GetTemplateViewAsync(
    params: { name?: string } | null | undefined
  ): Promise<SmsTemplate[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetTemplateView`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetPageBySendObjectGroup
   * 查询发送对象分组
   */ static async GetPageBySendObjectGroupAsync(
    params:
      | { name?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<SendObjectGroupEditModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetPageBySendObjectGroup`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetOneSendObjectGroup
   * 获取发送对象分组
   */ static async GetOneSendObjectGroupAsync(params: {
    id: number;
  }): Promise<SendObjectGroupEditModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetOneSendObjectGroup`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetPageBySmsSendTask
   * 查询发送任务
   */ static async GetPageBySmsSendTaskAsync(
    params:
      | { name?: string; offset?: number; limit?: number }
      | null
      | undefined
  ): Promise<IPagedEnumerable<SmsSendTaskViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetPageBySmsSendTask`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetOneSmsSendTask
   * 获取发送任务
   */ static async GetOneSmsSendTaskAsync(params: {
    id: number;
  }): Promise<SmsSendTask> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetOneSmsSendTask`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetAllSmsSendPersonnel
   * 获取所有所有联系人
   */ static async GetAllSmsSendPersonnelAsync(): Promise<SmsSendPersonnel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetAllSmsSendPersonnel`,
      responseType: "json",
    });
  }
  /**
   * GET /api/SmsManage/GetAllSendObjectGroup
   * 获取所有发送对象分组
   */ static async GetAllSendObjectGroupAsync(): Promise<SmsSendRoles[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SmsManage/GetAllSendObjectGroup`,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/SetBaseConfig
   * 设置短信基础配置
   */ static async SetBaseConfig_PostAsync(data: SmsKeysConfig): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/SetBaseConfig`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/SetAliyunConfig
   * 设置阿里云短信密钥
   */ static async SetAliyunConfig_PostAsync(
    data: AliyunAccessKey
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/SetAliyunConfig`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/SendSms
   * 批量发送短信
   */ static async SendSms_PostAsync(data: BatchSendSms): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/SendSms`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/AddSmsTemplate
   * 添加短信模板
   */ static async AddSmsTemplate_PostAsync(
    data: SmsTemplateEditModel
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/AddSmsTemplate`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/ModifySmsTemplate
   * 修改未审核通过的模板
   */ static async ModifySmsTemplate_PostAsync(
    data: SmsTemplateEditModel
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/ModifySmsTemplate`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/DeleteSmsTemplate
   * 删除短信模板
   */ static async DeleteSmsTemplate_PostAsync(
    params: { id?: string } | null | undefined
  ): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/DeleteSmsTemplate`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/AddSendObjectGroup
   * 添加发送对象分组
   */ static async AddSendObjectGroup_PostAsync(
    data: SendObjectGroupEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/AddSendObjectGroup`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/EditSendObjectGroup
   * 编辑发送对象分组
   */ static async EditSendObjectGroup_PostAsync(
    data: SendObjectGroupEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/EditSendObjectGroup`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/DeleteSendObjectGroup
   * 删除发送对象分组
   */ static async DeleteSendObjectGroup_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/DeleteSendObjectGroup`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/AddSmsSendTask
   * 添加发送任务
   */ static async AddSmsSendTask_PostAsync(
    data: SmsSendTaskEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/AddSmsSendTask`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/EditSmsSendTask
   * 编辑发送任务
   */ static async EditSmsSendTask_PostAsync(
    data: SmsSendTaskEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/EditSmsSendTask`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/DeleteSmsSendTask
   * 删除发送任务
   */ static async DeleteSmsSendTask_PostAsync(params: {
    id: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/DeleteSmsSendTask`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/SmsManage/SendSmsTask
   * 立即执行短信发送任务
   */ static async SendSmsTask_PostAsync(params: {
    taskId: number;
  }): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsManage/SendSmsTask`,
      params,
      responseType: "json",
    });
  }
}
export class SmsMessage {
  /**
   * POST /api/SmsMessage/SendVerificationCode
   * 发送短信验证码
   */ static async SendVerificationCode_PostAsync(
    params:
      | {
          /* 验证码类型 */ type?: VerificationType;
          captchaId?: string;
          code?: string;
          phone?: string;
        }
      | null
      | undefined
  ): Promise<string> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SmsMessage/SendVerificationCode`,
      params,
      responseType: "json",
    });
  }
}
export class SystemBaseInfo {
  /**
   * GET /api/SystemBaseInfo/Get
   * 获取站点基本信息
   */ static async GetAsync(): Promise<SystemInfo> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/SystemBaseInfo/Get`,
      responseType: "json",
    });
  }
  /**
   * POST /api/SystemBaseInfo/Save
   * 保存站点基本信息
   */ static async Save_PostAsync(data: SystemInfo): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/SystemBaseInfo/Save`,
      data,
      responseType: "json",
    });
  }
}
export class UserManage {
  /**
   * GET /api/UserManage/GetUserList
   * 返回用户列表
   */
  @ApiAction({
    id: "UserManage.GetUserList",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "返回用户列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetUserListAsync(
    params:
      | {
          /* 角色Id */ roleId?: number;
          /* 用户名 */ userName?: string;
          /* 邮箱 */ email?: string;
          /* 电话 */ phoneNumber?: string;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<UserViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/GetUserList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/GetUserViewList
   * 返回用户列表
   */
  @ApiAction({
    id: "UserManage.GetUserViewList",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "返回用户列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetUserViewListAsync(
    params:
      | {
          /* 角色Id */ roleId?: number;
          /* 用户名 */ userName?: string;
          /* 邮箱 */ email?: string;
          /* 电话 */ phoneNumber?: string;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<LongIdNameViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/GetUserViewList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/GetUserViewListRef
   */
  @ApiAction({
    id: "UserManage.GetUserViewListRef",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "GetUserViewListRef",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetUserViewListRefAsync(
    params:
      | {
          roleId?: number;
          userName?: string;
          email?: string;
          phoneNumber?: string;
        }
      | null
      | undefined
  ): Promise<LongIdNameViewModel[]> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/GetUserViewListRef`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/GetUserViewModel
   * 用户信息
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetUserViewModelAsync(params: {
    /* 用户ID */ userId: number;
  }): Promise<UserViewModel> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/GetUserViewModel`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/UserLoginLogList
   * 获取用户的登录日志
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async UserLoginLogList_GetAsync(params: {
    userId: number;
    offset?: number;
    limit?: number;
  }): Promise<IPagedEnumerable<UserLoginLog>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/UserLoginLogList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/LoginLogList
   * 获取所有用户的登录日志
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async LoginLogList_GetAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<UserLoginLog>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/LoginLogList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/RequestLogList
   * 获取所有用户的请求日志
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async RequestLogList_GetAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<UserRequestLog>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/RequestLogList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/UserRequestLogList
   * 获取用户的请求日志
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async UserRequestLogList_GetAsync(params: {
    /* 用户Id */ userId: number;
    offset?: number;
    limit?: number;
  }): Promise<IPagedEnumerable<UserRequestLog>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/UserRequestLogList`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/GetBareheadedPhoto
   * 获取用户的免冠照
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetBareheadedPhotoAsync(params: {
    /* 用户Id */ userId: number;
  }): Promise<EmbedFileInfo> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/GetBareheadedPhoto`,
      params,
      responseType: "json",
    });
  }
  /**
   * GET /api/UserManage/GetIdCardPhoto
   * 获取用户的身份证照片
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async GetIdCardPhotoAsync(params: {
    /* 用户Id */ userId: number;
  }): Promise<IdCardPhotoInfo> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/UserManage/GetIdCardPhoto`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserManage/EditUserModel
   * 修改用户信息
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EditUserModel_PostAsync(
    data: UserEditModel
  ): Promise<UserViewModel> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserManage/EditUserModel`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserManage/DeleteUser
   * 删除用户
   */
  @ApiAction({
    id: "UserManage.DeleteUser",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "删除用户",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async DeleteUser_PostAsync(params: {
    /* 用户Id */ userId: number;
  }): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserManage/DeleteUser`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserManage/EditUserPassword
   * 修改用户的密码
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EditUserPassword_PostAsync(
    data: UserPasswordChangeEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserManage/EditUserPassword`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserManage/EditUserExpiration
   * 修改用户过期时间
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EditUserExpiration_PostAsync(
    data: UserExpirationEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserManage/EditUserExpiration`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserManage/CreateUser
   * 创建用户
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async CreateUser_PostAsync(
    data: UserCreateModel
  ): Promise<UserViewModel> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserManage/CreateUser`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserManage/EditUserModifyPasswordEnd
   * 修改用户密码过期时间
   */
  @ApiAction({
    id: "UserManage",
    type: 0,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "用户管理",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async EditUserModifyPasswordEnd_PostAsync(
    data: UserExpirationEditModel
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserManage/EditUserModifyPasswordEnd`,
      data,
      responseType: "json",
    });
  }
}
export class UserNotices {
  /**
   * POST /api/UserNotices/Find
   * 获取当前登录用户通知列表
   */
  @ApiAction({
    id: "UserNotices.Find",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "获取当前登录用户通知列表",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async Find_PostAsync(
    params:
      | {
          keyword?: string;
          isReaded?: boolean;
          type?: NoticeType;
          offset?: number;
          limit?: number;
        }
      | null
      | undefined
  ): Promise<IPagedEnumerable<UserNoticeViewModel>> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserNotices/Find`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserNotices/FindOneViewById
   * 查看通知详情（查看数据后状态已读）
   */
  @ApiAction({
    id: "UserNotices.FindOneViewById",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "查看通知详情（查看数据后状态已读）",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async FindOneViewById_PostAsync(params: {
    id: number;
  }): Promise<UserNoticeViewModel> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserNotices/FindOneViewById`,
      params,
      responseType: "json",
    });
  }
  /**
   * POST /api/UserNotices/SetReaded
   * 标记未读
   */
  @ApiAction({
    id: "UserNotices.SetReaded",
    type: 1,
    builtRolesOnly: false,
    allowedBuiltRoles: null,
    allowedRoles: null,
    displayName: "标记未读",
    description: null,
    customValidator: false,
    defaultForbidden: true,
    logEnabled: true,
  })
  static async SetReaded_PostAsync(params: { id: number }): Promise<boolean> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/UserNotices/SetReaded`,
      params,
      responseType: "json",
    });
  }
}
export class WeChatApiKey {
  /**
   * GET /api/WeChatApiKey/GetCallbackToken
   * 获取微信信息回调Token
   */ static async GetCallbackTokenAsync(): Promise<WeChatCallbackToken> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/WeChatApiKey/GetCallbackToken`,
      responseType: "json",
    });
  }
  /**
   * GET /api/WeChatApiKey/GetApiKey
   * 获取微信APIKEY
   */ static async GetApiKeyAsync(): Promise<WeChatApiKeyEntity> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/WeChatApiKey/GetApiKey`,
      responseType: "json",
    });
  }
  /**
   * POST /api/WeChatApiKey/SaveCallbackToken
   * 保存微信信息回调Token
   */ static async SaveCallbackToken_PostAsync(
    data: WeChatCallbackToken
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/WeChatApiKey/SaveCallbackToken`,
      data,
      responseType: "json",
    });
  }
  /**
   * POST /api/WeChatApiKey/SaveApiKey
   * 保存微信APIKEY
   */ static async SaveApiKey_PostAsync(
    data: WeChatApiKeyEntity
  ): Promise<void> {
    return callPackedApiAsync({
      method: "POST",
      url: `/api/WeChatApiKey/SaveApiKey`,
      data,
      responseType: "json",
    });
  }
}
export class WeChatManage {
  /**
   * GET /api/WeChatManage/GetPage
   * 微信公众号关注列表
   */ static async GetPageAsync(
    params: { offset?: number; limit?: number } | null | undefined
  ): Promise<IPagedEnumerable<WeChatUserInfoViewModel>> {
    return callPackedApiAsync({
      method: "GET",
      url: `/api/WeChatManage/GetPage`,
      params,
      responseType: "json",
    });
  }
}
export class Files {
  /**
   * GET /files/manage/{id}/{fileName}
   * 文件下载
   */ static async Manage_GetAsync(params: {
    fileName: string;
    id: number;
  }): Promise<AxiosResponse<Blob>> {
    return callSourceApiAsync({
      method: "GET",
      url: `/files/manage/${params.id}/${params.fileName}`,
      responseType: "blob",
    }) as unknown as AxiosResponse<Blob>;
  }
  /**
   * GET /files/p/{id}/{fileName}
   * 门户网站文件下载
   */ static async P_GetAsync(params: {
    fileName: string;
    id: number;
  }): Promise<AxiosResponse<Blob>> {
    return callSourceApiAsync({
      method: "GET",
      url: `/files/p/${params.id}/${params.fileName}`,
      responseType: "blob",
    }) as unknown as AxiosResponse<Blob>;
  }
}
