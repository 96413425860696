/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-05-25 09:51:26
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/sms',
    component: DefaultLayout,
    meta: { title: '短信管理', icon: 'SettingOutlined' },
    redirect: '/config/base',

    children: [
      {
        path: 'tempalte',
        name: 'tempalte',
        component: () => import('@/views/sms/tempalte/index.vue'),
        meta: { title: '短信模板', icon: 'team-outlined' },
      },
      {
        path: 'group',
        name: 'Group',
        component: () => import('@/views/sms/group/index.vue'),
        meta: { title: '发送对象分组', icon: 'team-outlined' },
      },
      {
        path: 'task',
        name: 'Task',
        component: () => import('@/views/sms/task/index.vue'),
        meta: { title: '短信发送任务', icon: 'schedule-outlined' },
      },
    ],
  },
];

export default routesMap;
export const order = 99;
