<!--
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-08 17:56:08
-->
<script setup lang="ts">
import { createVNode } from 'vue';
import { camelCase, upperFirst } from 'lodash-es';
import * as $Icon from '@ant-design/icons-vue';
import type { RouteRecordRaw } from 'vue-router';
import { useRouter } from 'vue-router';
import { PicRightOutlined } from '@ant-design/icons-vue';

interface IProps {
  menuInfo: RouteRecordRaw;
  parentPath?: string;
}

defineProps<IProps>();

function Icon({ icon }: { icon: any }) {
  const k = upperFirst(camelCase(icon));
  return createVNode(($Icon as any)[k]);
}

const router = useRouter();

function getPath(path: string) {
  return `${path}/`.replace('//', '/');
}

function routeJoin(...args: (string | null | undefined)[]) {
  return Array.from(new Set(args.filter(Boolean))).join('/');
}
</script>

<template>
  <a-menu-item
    v-if="!menuInfo.children || menuInfo.children.length === 0 || menuInfo.children.length === 1"
    :key="getPath(`${parentPath}/${menuInfo.path}`)"
  >
    <template #icon>
      <Icon v-if="menuInfo?.meta?.icon" :icon="menuInfo.meta?.icon" />
      <PicRightOutlined v-else />
    </template>
    <router-link :to="getPath(routeJoin(parentPath, menuInfo.path, menuInfo.children?.[0].path))">
      {{ menuInfo.meta?.title }}
    </router-link>
  </a-menu-item>

  <a-sub-menu v-else :key="menuInfo.path">
    <template #icon>
      <Icon v-if="menuInfo?.meta?.icon" :icon="menuInfo.meta?.icon" />
      <PicRightOutlined v-else />
    </template>
    <template #title>
      {{ menuInfo.meta?.title }}
    </template>
    <template v-for="item in menuInfo.children" :key="item.path">
      <template v-if="item.children === null || item.children?.length === 0 || item.children?.length === 1">
        <a-menu-item :key="getPath(`${parentPath}/${item.path}`)">
          <template #icon>
            <Icon v-if="item?.meta?.icon" :icon="item.meta?.icon" />
          </template>
          <router-link :to="getPath(`${parentPath}/${item.path}`)">
            {{ menuInfo.meta?.title }}
          </router-link>
        </a-menu-item>
      </template>

      <template v-else>
        <sub-menu
          v-if="menuInfo.children?.length !== 1"
          :key="item.path"
          :menu-info="item"
          :parent-path="menuInfo.path"
        />
        <template v-else>
          <a-menu-item :key="getPath(`${parentPath}/${item.path}`)">
            <template #icon>
              <Icon v-if="item?.meta?.icon" :icon="item.meta.icon" />
            </template>

            <router-link :to="getPath(`${parentPath}/${item.path}`)">
              {{ menuInfo.meta?.title }}
            </router-link>
          </a-menu-item>
        </template>
      </template>
    </template>
  </a-sub-menu>
</template>

<style scoped></style>
