/*
 * @Descripttion:
 * @Author: Xinner
 * @Date: 2021-03-19 10:36:21
 * @LastEditors: luckymiaow
 * @LastEditTime: 2023-10-26 11:01:20
 */

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import { Modal, message, notification } from 'ant-design-vue';
import type { RequestOptions } from '@/.generated/apis';
import { apiOptions } from '@/.generated/apis';
import { useAuthStore } from '@/stores';
import { whiteListByCurrentPath } from '@/router/guard/whiteList';

// axios.interceptors.request.use((config) => {
//   if (Intl.DateTimeFormat().resolvedOptions().timeZone === 'Asia/Shanghai' && config.data && config.data instanceof Object) {
//     for (const key in config.data) {
//       if (config.data[key] instanceof Date) {
//         // 将 Date 类型转换为 ISO 8601 格式字符串
//         config.data[key] = new Date(config.data[key].getTime() + 8 * 60 * 60 * 1000);
//       }
//     }
//   }
//   return config;
// });

export const request = apiOptions.ajaxRequestAsync = async function<TData, TResult>(
  options: RequestOptions<TData>,
): Promise<TResult | AxiosResponse<TResult>> {
  const { getToken } = useAuthStore();

  const headers = { ...(options.headers || {}) };
  if (getToken)
    headers.Authorization = `Bearer ${getToken}`;

  const result = await axios.request<TData, AxiosResponse<TResult>>({
    ...options,
    headers,
    withCredentials: false,
    baseURL: import.meta.env.VITE_APP_PROXY_TARGET,
  });

  if (options.responseType === 'blob')
    return result;

  const { data } = result;
  return data;
};

export function errMessage() {
  const { signOut } = useAuthStore();
  if (!whiteListByCurrentPath()) {
    Modal.info({
      content: '您的登录凭证已经过期，请重新登录',
      title: '重新登录',
      okText: '重新登录',
      onOk: () => {
        signOut();
        location.href = '/';
        Modal.destroyAll();
      },
    });
  }
}

// respone 拦截器
axios.interceptors.response.use(
  async (response) => {
    const { data } = response;

    if (data.code === 401)
      errMessage();

    if (Object.keys(data).includes('code')) {
      if (data.code !== 200) {
        console.log('服务器操作错误：', data.data);
        notification.error({
          message: '服务调用失败',
          description: `错误代码：${data.code}，异常消息：${data.message}`,
        });
        throw new Error(data.message);
      }
    }
    return response;
  },
  (error) => {
    let msg = error.toString();
    if (error.response) {
      console.log('API ERROR error.response:', error.response);
      switch (error.response.status) {
        case 401:
          errMessage();
          break;
        case 403:
          msg = `code=403: ${error.response.data.message}`;
          message.error(msg);
          break;
        case 500:
          msg = `code=500: 服务器错误:${error.response.data} `;
          console.log(msg, error);
          message.error(msg);
          break;
        default:
          if (error.response.data.message) {
            msg = `code=${error.response.status}: ${error.response.data.message}`;
            message.error(msg);
          }
      }
    }
    else {
      console.log('API ERROR:', error);
      Modal.confirm({ content: `错误:response=${error}`, title: '错误' });
    }
    return Promise.reject(error.response.data.message ? msg : error);
  },
);
