/*
 * @Author: xinner
 * @Date: 2022-03-24 11:02:04
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-06-29 16:43:04
 * @Description:
 */
import type { RouteRecordRaw, RouterOptions } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import type { App } from 'vue';
import { message } from 'ant-design-vue';
import { useUserStore } from '../stores/modules/user';
import createRouterGuards from './guard/routerPermission';
import { whiteList, whiteListByCurrentPath, whiteListTest } from './guard/whiteList';
import { appRoutes } from './routes';
import { useAuthStore } from '@/stores';

const HOME_PATH = import.meta.env.VITE_APP_VIEW_HOME_PATH;

export const staticRoutes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'Home', hidden: true },
    redirect: HOME_PATH,
    component: () => import('@/views/index.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    meta: { title: '404', hidden: true },
    component: () => import('@/views/404.vue'),
  },
  ...appRoutes,
];

export function dyRoutes() {
  const { activeUser } = useUserStore();
  const role = activeUser.roles?.[0]?.name;
  if (role === '推文管理')
    return staticRoutes.filter(v => [...whiteList, '/information-manage', '/editor', '/user-center', '/logoutReload'].includes(v.path) || v.name === 'NotFound');
  return staticRoutes;
}

/**
 * @description: 页面初始化
 * @param undefined
 * @return {*}
 * @author: luckymiaow
 */
export async function setupRouter(app: App<Element>) {
  const { access_token } = useAuthStore();
  const { getUserInfo } = useUserStore();

  if (whiteListByCurrentPath() === false) {
    try {
      if (access_token)
        getUserInfo(true);
    }
    catch (err: any) {
      message.error(err.message);
    }
  }

  const config: RouterOptions = {
    history: createWebHistory(),
    scrollBehavior: (to, from, savedPosition) => {
      if (savedPosition)
        return savedPosition;

      return { top: 0 };
    },
    routes: dyRoutes(),
  };

  const router = createRouter(config);

  createRouterGuards(router, whiteListTest);

  app.use(router);

  return router;
}
