/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-08 16:50:48
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/user-center',
    name: 'UserCenter',
    meta: { title: '用户中心', hidden: true },
    component: DefaultLayout,
    redirect: 'user-setting',
    children: [
      {
        path: 'user-setting',
        component: () => import('@/views/user-center/index.vue'),
        name: 'UserSetting',
        meta: {
          title: '个人中心',
        },
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    meta: { title: '', hidden: true },
    component: () => import('@/views/protal/index.vue'),
  },
  {
    path: '/logoutReload',
    meta: { title: '', hidden: true },
    component: () => import('@/views/logoutReload.vue'),
  },
  {
    path: '/logout',
    component: DefaultLayout,
    meta: { title: '', hidden: true },
    children: [
      {
        path: '',
        component: () => import('@/views/logout.vue'),
        name: 'Logout',
        meta: {
          title: '注销登录',
          icon: 'example',
          noCache: true,
          hidden: true,
        },
      },
    ],
  },
  {
    path: '/logout-callback',
    redirect: '/login',
    meta: { title: '', hidden: true },
    name: 'LogoutCallback',
    component: () => import('@/views/logoutCallback.vue'),
    children: [],
  },
  {
    path: '/oidc-callback',
    name: 'OidcCallback',
    meta: { title: '', hidden: true },
    component: () => import('@/views/oidcCallback.vue'),
  },
  {
    path: '/403',
    name: ' NotAuthorized',
    meta: { title: '', hidden: true },
    component: () => import('@/views/403.vue'),
  },
  {
    path: '/404',
    name: 'notFound',
    meta: { title: '', hidden: true },
    component: () => import('@/views/404.vue'),
  },

  {
    path: '/:pathMatch(.*)*',
    meta: { title: '', hidden: true },
    redirect: '/404',
  },
];

export default routesMap;
