import { UserRoleViewModel } from "./UserRoleViewModel";
export class UserViewModel {
  id: number = 0;
  name: string | null | undefined = null;
  email: string | null | undefined = null;
  phoneNumber: string | null | undefined = null;
  roles: UserRoleViewModel[] | null | undefined = [];
  userName: string | null | undefined = null;

  /* 邮箱是否验证 */
  emailConfirmed: boolean = false;

  /* 手机号是否验证 */
  phoneNumberConfirmed: boolean = false;

  /* 账号过期时间 */
  expiration: Date | null | undefined = null;

  /* 密码修改时限 */
  modifyPasswordEnd: Date | null | undefined = null;

  /* 是否已实名 */
  isVerified: boolean = false;
}
