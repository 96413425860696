/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-05-25 09:51:26
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/system',
    component: DefaultLayout,
    meta: { title: '系统管理', icon: 'SettingOutlined' },
    redirect: '/system/user',
    children: [
      {
        path: 'user',
        name: 'User',
        component: () => import('@/views/system/user/index.vue'),
        meta: { title: '用户管理', icon: 'team-outlined' },
      },
      {
        path: 'role',
        name: 'Role',
        component: () => import('@/views/system/role/index.vue'),
        meta: { title: '角色管理', icon: 'team-outlined' },
      },
      {
        path: 'log',
        name: 'Log',
        component: () => import('@/views/system/log/index.vue'),
        meta: { title: '用户日志', icon: 'schedule-outlined' },
      },
    ],
  },
];

export default routesMap;
