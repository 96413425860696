/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-12 09:22:05
 */
import type { App } from 'vue';
import { dragSimple } from './drag-simple.directive';
import { dragDirective } from './drag.directive';

export async function setupDirective(app: App<Element>) {
  app.directive('drag-simple', dragSimple);
  app.directive('drag', dragDirective);
}
