<!--
 * @Description:
 * @Author: 景 彡
 * @Date: 2023-07-12 08:54:07
 * @LastEditors: Please set LastEditors
-->
<script setup lang="ts">
import type { RouteLocationNormalizedLoaded } from 'vue-router';
import { useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';

interface Route {
  path: string;
  breadcrumbName: string;
  children?: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}

const router = useRouter();

const persistedRoutes = sessionStorage.getItem('breadcrumbRoutes');
const routes = ref<Route[]>(persistedRoutes ? JSON.parse(persistedRoutes) : []);

const breadcrumb = computed(() => {
  const res: Route[] = [];
  routes.value.forEach((e) => {
    if (!res.some(v => v.path === e.path))
      res.push(e);
  });
  return res;
});

watch(
  () => router.currentRoute.value,
  (to, from) => {
    generateBreadcrumbRoutes(to, from);
  },
  { immediate: true },
);

function generateBreadcrumbRoutes(to: RouteLocationNormalizedLoaded, from: RouteLocationNormalizedLoaded | undefined) {
  const matchedRoutes = to.matched;
  const toIndex = matchedRoutes.length - 1;
  const fromIndex = from ? matchedRoutes.findIndex(route => route.path === from.path) : -1;

  if (fromIndex === -1 || toIndex < fromIndex) {
    // Navigating forward or initial load
    routes.value = matchedRoutes.map(route => ({
      path: `${route.path}/`.replace('//', '/'),
      breadcrumbName: route.meta && route.meta.title ? route.meta.title : '',
    }));
  }
  else if (fromIndex >= 0) {
    // Navigating backward
    routes.value = routes.value.slice(0, fromIndex + 1);
  }

  sessionStorage.setItem('breadcrumbRoutes', JSON.stringify(routes.value));
}
</script>

<template>
  <a-breadcrumb :routes="breadcrumb">
    <template #itemRender="{ route }">
      <router-link :to="route.path">
        {{ route.breadcrumbName }}
      </router-link>
    </template>
  </a-breadcrumb>
</template>
