/*
 * @Description:
 * @Author: luckymiaow
 * @Date: 2022-04-26 15:13:32
 * @LastEditors: 景 彡
 */
/*
 * @Author: xinner
 * @Date: 2022-04-25 09:58:09
 * @LastEditors: luckymiaow
 * @LastEditTime: 2023-07-27 10:44:53
 * @Description:
 */
import { createVNode } from 'vue';
import * as $Icon from '@ant-design/icons-vue';
import { camelCase, upperFirst } from 'lodash-es';

function Icon(props: { icon: string }) {
  const { icon } = props;
  const k = upperFirst(camelCase(icon));
  return createVNode(($Icon as any)[k]);
}

export default Icon;
