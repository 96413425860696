<!--
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-14 16:40:45
-->
<script setup lang="ts">
import { LogoutOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons-vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useUserStore } from '../../../stores/modules/user';
import { useAppStore } from '@/stores/modules/app';
import { useAuthStore } from '@/stores/modules/auth';

const { signOut } = useAuthStore();

const router = useRouter();

const userStore = useUserStore();

const userState = computed(() => userStore);

const { activeUser } = userState.value;

const { $state } = useAppStore();

const simulated = computed(() => userState.value.isSimulate);

const primaryColor = computed(() => $state.primaryColor);

async function logout() {
  router.push('/logoutReload');
}
</script>

<template>
  <div>
    <a-menu mode="horizontal" v-bind="$attrs" style="margin: auto 66px">
      <a-sub-menu key="title">
        <template #icon>
          <a-avatar :style="{ backgroundColor: primaryColor }">
            <template #icon>
              <UserOutlined />
            </template>
          </a-avatar>
        </template>
        <template #title>
          <span style="font-size: 16px">
            {{ activeUser.name || activeUser.userName }}
          </span>
        </template>
        <a-menu-item key="个人中心" @click="$router.push('/user-center/user-setting')">
          <template #icon>
            <SolutionOutlined />
          </template>
          个人中心
        </a-menu-item>
        <a-menu-item key="退出登录" @click="logout">
          <template #icon>
            <LogoutOutlined />
          </template>
          退出登录
        </a-menu-item>
      </a-sub-menu>
    </a-menu>
  </div>
</template>
