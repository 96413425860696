/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-04-10 10:04:52
 */
import { defineStore } from 'pinia';
import type { EmbedFileInfo, IdCardPhotoInfo, UserEditModel, UserRoleViewModel } from '@/.generated/models';
import { UserViewModel } from '@/.generated/models';

import { UserService } from '@/api/services/user';
import * as api from '@/api';

export class User extends UserViewModel {
  id = 0;

  name: string | null | undefined = null;

  email: string | null | undefined = null;

  phoneNumber: string | null | undefined = null;

  roles: UserRoleViewModel[] | null | undefined = [];

  /* 邮箱是否验证 */
  emailConfirmed = false;

  /* 手机号是否验证 */
  phoneNumberConfirmed = false;

  /* 账号过期时间 */
  expiration: Date | null | undefined = null;

  /* 密码修改时限 */
  modifyPasswordEnd: Date | null | undefined = null;

  idCardPhoto?: IdCardPhotoInfo | null | undefined = null;

  bareheadedPhoto?: EmbedFileInfo | null | undefined = null;
}

export interface IUserState {
  activeUser: User;

  userList: User[];
}

export const useUserStore = defineStore('user', {
  state: (): IUserState => ({
    activeUser: new User(),
    userList: [],
  }),
  getters: {
    isSimulate(): boolean {
      const [firstUser] = this.userList;
      return this.userList.length > 1 && firstUser.id !== this.activeUser.id;
    },

  },
  actions: {
    async getUserInfo(cover: boolean = false) {
      try {
        if (this.activeUser.id && !cover)
          return this.activeUser;
        const user = await UserService.getUserInfo();
        if (this.userList.map(e => e.id)?.indexOf(user.id) === -1)
          this.userList.push(user);

        this.$patch({ activeUser: user });
        return user;
      }
      catch (error) {
        return this.activeUser;
      }
    },

    async  getIdCardPhoto(forcedUpdating = false): Promise<IdCardPhotoInfo | undefined> {
      if (!this.activeUser.idCardPhoto || forcedUpdating) {
        const idCardPhoto = await api.CurrentUser.GetIdCardPhotoAsync();

        this.activeUser.idCardPhoto = Object.keys(idCardPhoto).length > 0 ? idCardPhoto : undefined;
      }
      return this.activeUser.idCardPhoto;
    },
    async getBareheadedPhoto(forcedUpdating = false): Promise<EmbedFileInfo | undefined> {
      if (!this.activeUser.bareheadedPhoto || forcedUpdating) {
        const res = await api.CurrentUser.GetBareheadedPhotoAsync();
        this.activeUser.bareheadedPhoto = res;
      }
      return this.activeUser.bareheadedPhoto;
    },
    async  setPhotoInfo() {
      const idCardPhoto = await api.CurrentUser.GetIdCardPhotoAsync();
      const bareheadedPhoto = await api.CurrentUser.GetBareheadedPhotoAsync();
      this.activeUser.idCardPhoto = Object.keys(idCardPhoto).length > 0 ? idCardPhoto : undefined;
      this.activeUser.bareheadedPhoto = bareheadedPhoto;
    },

    async switchUser(id: number): Promise<User> {
      if (this.userList.length > 1) {
        await UserService.simulateExit();

        this.simulateSignIn(id);

        // const index = this.userList.findIndex(e => e[key as keyof typeof e] === account);
        // if (index !== -1) {
        //   const user = this.userList[index];
        //   this.resetInfo();
        //   Object.assign(this.activeUser, user);
        //   return user;
        // }
      }
      return this.activeUser;
    },
    async updateUserInfo(user: UserEditModel): Promise<boolean> {
      try {
        const res = await UserService.modifiedUserInfo(user);

        if (!res?.id)
          return false;

        const index = this.userList.findIndex(e => e.id === user.id);

        if (index !== -1) {
          this.$patch({ activeUser: user });

          this.userList[index] = this.activeUser;
        }
        return true;
      }
      catch (error) {
        return false;
      }
    },
    async simulateSignIn(userId: number) {
      try {
        const res = await UserService.getSimulateStatus();

        if (!res) {
          const simulateUser = await UserService.simulateSignIn(userId);

          const index = this.userList.findIndex(e => e.id === simulateUser.id);

          if (index === -1)
            this.userList.push(simulateUser);

          else
            this.userList[index] = simulateUser;

          this.resetInfo();
          Object.assign(this.activeUser, simulateUser);

          location.reload();
        }
      }
      catch (error) {
        console.log('%c [ error ]-118', 'font-size:13px; background:#a05d61; color:#e4a1a5;', error);
      }
    },
    async simulateExit() {
      try {
        await UserService.simulateExit();

        if (this.userList.length > 1) {
          this.resetInfo();
          const [admin] = this.userList;
          Object.assign(this.activeUser, admin);
        }
        else {
          this.clear();
        }
        location.reload();
      }
      catch (error) {
        console.log('%c [ error ]-133', 'font-size:13px; background:#daf3b2; color:#fffff6;', error);
      }
    },
    clear() {
      this.$reset();
    },
    resetInfo() {
      Object.assign(this.activeUser, new User());
    },
  },
});
