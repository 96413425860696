/*
 * @Description:
 * @Author: luckymiaow
 * @Date: 2022-06-02 11:21:19
 * @LastEditors: 景 彡
 */
/*
 * @Description:
 * @Autor: luckymiaow
 * @Date: 2021-07-01 14:57:45
 * @LastEditors: xinner
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/information-manage',
    component: DefaultLayout,
    redirect: '/information-manage/information',
    meta: { title: '资讯管理', icon: 'alert-outlined' },
    children: [
      {
        path: 'category',
        component: () => import('@/views/information-manage/category/index.vue'),
        name: 'information-manage-category',
        meta: { title: '资讯分类', icon: 'alert-outlined' },
      },
      {
        path: 'information',
        component: () => import('@/views/information-manage/information/index.vue'),
        name: 'information-manage-information',
        meta: { title: '资讯列表' },
      }, {
        path: 'leaving-message',
        component: () => import('@/views/information-manage/leaving-message/index.vue'),
        name: 'leaving-message',
        meta: { title: '留言列表' },
      },

    ],
  },
  {
    path: '/editor',
    name: 'Editor',
    meta: { title: '', hidden: true },
    component: () => import('@/views/information-manage/information/components/EditorNew.vue'),
    children: [],
  },
];

export default routesMap;
