/*
 * @Author: joyet
 * @Date: 2023-06-27 10:48:25
 * @LastEditTime: 2023-07-12 09:40:55
 * @LastEditors: nono
 * @Description:
 * @FilePath: \action-research-association-admin-vue\src\router\routes\modules\contacts.ts
 *
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/notice-manage',
    component: DefaultLayout,
    meta: { title: '通知管理', icon: 'notification-outlined' },
    children: [
      {
        path: '',
        component: () => import('@/views/notices-manage/index.vue'),
        name: 'noticeManage',
        meta: { title: '通知管理' },
      },
    ],
  },
];

export default routesMap;
