/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-09 11:33:33
 */
// export type Theme = 'light' | 'dark';

// export type Layout = 'side' | 'top' | 'mix';

// export type ContentWidth = 'fixed' | 'fluid';

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export enum Layout {
  side = 'side',
  top = 'top',
  mix = 'mix',
}

export enum ContentWidth {
  fixed = 'fixed',
  fluid = 'fluid',
}

export interface IAppState {
  // Page style setting
  theme?: Theme;
  // Theme Color
  primaryColor?: string;
  // Navigation Mode
  layout?: Layout;
  // Content Width
  contentWidth?: ContentWidth;
  // Fixed Header
  fixedHeader?: boolean;
  // Fixed Sidebar
  fixSidebar?: boolean;
  // Split Menus
  splitMenus?: boolean;
  pwa?: boolean;
  logo?: string;
  headerHeight?: number;

  /**
   *Regional Settings
   *
   * @memberof IAppState
   */
  // Header
  headerRender?: boolean;
  // Footer
  footerRender?: boolean;
  // Menu
  menuRender?: boolean;
  // Menu Header
  menuHeaderRender?: boolean;
}
