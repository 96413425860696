/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-12 16:14:27
 */
import type { IAppState } from '@/types/theme';
import { ContentWidth, Layout, Theme } from '@/types/theme';

export const appSetting: IAppState = {
  theme: Theme.dark,
  primaryColor: '#1890ff',
  layout: Layout.side,
  contentWidth: ContentWidth.fluid,
  fixedHeader: false,
  fixSidebar: true,
  pwa: false,
  logo: 'https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg',
  headerHeight: 48,
  splitMenus: false,
  headerRender: true,
  footerRender: true,
  menuRender: true,
  menuHeaderRender: true,
};
