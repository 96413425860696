/*
 * @Author: joyet
 * @Date: 2023-06-27 10:48:25
 * @LastEditTime: 2023-08-14 15:25:20
 * @LastEditors: 景 彡
 * @Description:
 * @FilePath: \action-research-association-admin-vue\src\router\routes\modules\contacts.ts
 *
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/contacts-manage',
    component: DefaultLayout,
    meta: { title: '通讯录', icon: 'usergroup-add-outlined' },
    children: [
      {
        path: '',
        component: () => import('@/views/contacts-manage/index.vue'),
        name: 'contactsManage',
        meta: { title: '通讯录', icon: 'usergroup-add-outlined' },
      },
    ],
  },
];

export default routesMap;
