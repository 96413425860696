/*
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-05-25 09:51:26
 */
import type { RouteRecordNormalized, RouteRecordRaw } from 'vue-router';

const modules = import.meta.glob('./modules/*.ts', { eager: true });
const externalModules = import.meta.glob('./externalModules/*.ts', {
  eager: true,
});

function formatModules(_modules: any, result: RouteRecordNormalized[]) {
  const temp: { order: number; result: RouteRecordNormalized[] }[] = [];

  Object.keys(_modules).forEach((key) => {
    const defaultModule = _modules[key].default;
    if (!defaultModule)
      return;
    const moduleList: RouteRecordNormalized[] = Array.isArray(defaultModule) ? [...defaultModule] : [defaultModule];
    const sort = (nodes: RouteRecordRaw[]) => {
      nodes.sort((a, b) => Number(b.meta?.order || -1) - Number(a.meta?.order || -1));
      nodes.forEach((e) => {
        if (e.children && e.children.length > 0)
          sort(e.children);
      });
    };
    sort(moduleList);
    temp.push({
      order: _modules[key]?.order || -1,
      result: moduleList,
    });
  });
  temp.sort((a, b) => b.order - a.order);
  temp.forEach((item) => {
    result.push(...item.result);
  });
  return result;
}

export const appRoutes: RouteRecordNormalized[] = formatModules(modules, []);

export const appExternalRoutes: RouteRecordNormalized[] = formatModules(externalModules, []);
