/*
 * @Description: 简单拖拽 不提供自动吸附以及边缘限制
 * @Author: sharebravery
 * @Date: 2023-07-04 10:09:12
 */
/* eslint-disable @typescript-eslint/no-use-before-define */
import type { ObjectDirective } from 'vue';

interface HTMLElementWithDrag extends HTMLElement {
  isDragging: boolean;
}

const CLICK_THRESHOLD = 5; // 设置点击阈值（误差）

const dragSimple: ObjectDirective<HTMLElementWithDrag> = {
  mounted(el) {
    el.isDragging = false;
    el.style.position = 'absolute'; // 添加绝对定位样式
    el.style.zIndex = '999'; // 添加绝对定位样式

    let initialX = 0;
    let initialY = 0;
    let offsetX = 0;
    let offsetY = 0;
    const clickThreshold = CLICK_THRESHOLD; // 设置点击阈值（误差）

    const handleMouseDown = (event: MouseEvent) => {
      if (event.button !== 0)
        return; // 检查是否是鼠标左键
      initialX = event.clientX;
      initialY = event.clientY;
      offsetX = el.offsetLeft;
      offsetY = el.offsetTop;

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseMove = (event: MouseEvent) => {
      const dx = event.clientX - initialX;
      const dy = event.clientY - initialY;

      if (!el.isDragging && (Math.abs(dx) > clickThreshold || Math.abs(dy) > clickThreshold)) {
        // 超过阈值，触发拖拽
        el.isDragging = true;
        el.style.pointerEvents = 'none'; // 取消元素的 pointer-events，防止拖拽过程中触发子元素的事件
      }

      if (el.isDragging) {
        el.style.left = `${offsetX + dx}px`;
        el.style.top = `${offsetY + dy}px`;

        // 将位置信息存入本地缓存
        const position = { left: el.style.left, top: el.style.top };
        localStorage.setItem('dragPosition', JSON.stringify(position));
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);

      if (el.isDragging) {
        el.isDragging = false;
        el.style.pointerEvents = ''; // 恢复元素的 pointer-events
      }
      else {
        // 未触发拖拽，执行点击事件
        el.click();
      }
    };

    el.addEventListener('mousedown', handleMouseDown);

    // 页面加载时恢复位置信息
    const storedPosition = localStorage.getItem('dragPosition');
    if (storedPosition) {
      const position = JSON.parse(storedPosition);
      el.style.left = position.left;
      el.style.top = position.top;
    }
  },
};

export { dragSimple };
