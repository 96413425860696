import type { CurrentUserPasswordChangeEditModel, UserEditModel, UserViewModel } from '../models';
import * as api from '@/api';

export class UserService {
  static getUserInfo() {
    return api.CurrentUser.Me_GetAsync();
  }

  /**
   *修改用户信息
   *
   * @static
   * @param {UserEditModel} data
   * @return {*}  {Promise<UserViewModel>}
   * @memberof UserService
   */
  static modifiedUserInfo(data: UserEditModel): Promise<UserViewModel> {
    return api.CurrentUser.EditUserModel_PostAsync(data);
  }

  /**
   * 修改用户密码
   *
   * @static
   * @param {UserPasswordChangeEditModel} data
   * @return {*}  {Promise<void>}
   * @memberof UserService
   */
  static modifiedUserPassword(data: CurrentUserPasswordChangeEditModel) {
    return api.CurrentUser.EditUserPassword_PostAsync(data);
  }

  static getLoginLogList(params: { offset?: number; limit?: number } | null | undefined) {
    return api.CurrentUser.CurrentUserLoginLogList_GetAsync(params);
  }

  static getSimulateStatus() {
    return api.Simulate.GetSimulateStatusAsync();
  }

  static simulateSignIn(userId: number) {
    return api.Simulate.SimulateLogin_PostAsync({ userId });
  }

  static simulateExit() {
    return api.Simulate.ExitSimulate_PostAsync();
  }
}
