<!--
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-08 17:50:13
-->
<script setup lang="ts">
import type { RouteRecordRaw } from 'vue-router';
import { onBeforeMount, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import SubMenu from './SubMenu.vue';

interface IProps {
  data: RouteRecordRaw[];
}

defineProps<IProps>();

const homePath = import.meta.env.VITE_APP_VIEW_HOME_PATH;

const openKeys = ref<string[]>([homePath]);

const selectedKeys = ref<string[]>([]);

const router = useRouter();

onBeforeMount(() => {
  updateSelectedKeys(router.currentRoute.value);
});

watch(router.currentRoute, (to) => {
  updateSelectedKeys(to);
});

function updateSelectedKeys(route: any) {
  const currentPath = route.path;

  const arr = currentPath.split('/');

  arr.shift();

  const [parent, ...children] = arr;

  if (!openKeys.value.includes(parent)) {
    if (openKeys.value.length >= 2)
      openKeys.value.shift();

    openKeys.value.push(`/${parent}`);
  }

  selectedKeys.value = [`/${parent}/${children.join('/') || '/'}`.replace('//', '/')];
}

function openChange(keys: any[]) {
  if (keys.length > 2)
    keys.shift();

  openKeys.value = keys;
}
</script>

<template>
  <a-menu v-model:selectedKeys="selectedKeys" :open-keys="openKeys" mode="inline" @openChange="openChange">
    <template v-for="item in data" :key="item.path">
      <SubMenu :menu-info="item" :parent-path="item.path" />
    </template>
  </a-menu>
</template>
