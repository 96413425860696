/*
 * @Author: joyet
 * @Date: 2023-06-27 10:48:25
 * @LastEditTime: 2023-08-14 16:11:20
 * @LastEditors: luckymiaow
 * @Description:
 * @FilePath: \action-research-association-admin-vue\src\router\routes\modules\contacts.ts
 *
 */
import type { RouteRecordRaw } from 'vue-router';
import DefaultLayout from '@/layout/DefaultLayout.vue';

const routesMap: RouteRecordRaw[] = [
  {
    path: '/exam-question-manage',
    component: DefaultLayout,
    meta: { title: '试题管理', icon: 'file-text-outlined' },
    children: [
      // {
      //   path: '',
      //   component: () => import('@/views/exam-question-manage/index.vue'),
      //   name: 'examQuestionManage',
      //   meta: { title: '试卷管理', icon: 'file-text-outlined' },
      // },
      {
        path: 'questions-manage',
        component: () => import('@/views/question-manage/index.vue'),
        name: 'questionsManage',
        meta: { title: '试题管理', icon: 'profile-outlined' },
      },
      {
        path: 'knowledge-manage',
        component: () => import('@/views/knowledge-manage/index.vue'),
        name: 'knowledgeManage',
        meta: { title: '知识点管理', icon: 'read-outlined' },
      },
    ],
  },
  // TODO 添加到考核考卷 tab
  // {
  //   path: '/exam-management',
  //   component: DefaultLayout,
  //   meta: { title: '考试管理', icon: 'container-outlined' },
  //   children: [

  //     {
  //       path: 'mockExam-manage',
  //       component: () => import('@/views/mockexam-manage/index.vue'),
  //       name: 'mockExamManage',
  //       meta: { title: '模拟考试管理', icon: 'aim-outlined' },
  //     },
  //   ],
  // },

];

export default routesMap;
