<!--
 * @Description:
 * @Author: luckymiaow
 * @Date: 2022-04-15 16:15:09
 * @LastEditors: 景 彡
-->
<script lang="ts" setup>
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { computed, onBeforeMount } from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { ConfigProvider } from 'ant-design-vue';
import useRouteLocal from '@/router/guard/useRouteLocal';
import { useAppStore, useAuthStore } from '@/stores';
import * as api from '@/api';

const auth = useAuthStore();

const { primaryColor } = useAppStore();

const editor = {
  hoverbarKeys: {
    attachment: {
      menuKeys: ['downloadAttachment'], // “下载附件”菜单
    },

  },
  MENU_CONF: {
    uploadImage: {
      // base64LimitSize: 10 * 1024 * 1024, // 10M 以下插入 base64
      async customUpload(file: File, insertFn: any) { // TS 语法
        const data = new FormData ();
        data.append('file', file);
        const res = await api.FileManage.UploadPortalFile_PostAsync(data);
        res.forEach((e) => {
          const path = `/files/p/${e.id}/${e.path}`;
          insertFn(path, e.fileName!, path);
        });
      },
    },
    uploadVideo: {
      async customUpload(file: File, insertFn: (url: string, poster?: string) => void) {
        const data = new FormData ();
        data.append('file', file);
        const res = await api.FileManage.UploadPortalFile_PostAsync(data);
        res.forEach((e) => {
          const path = `/files/p/${e.id}/${e.path}`;
          insertFn(path);
        });
      },
    },
    uploadAttachment: {
      async customUpload(file: File, insertFn: (url: string, poster?: string) => void) {
        const data = new FormData ();
        data.append('file', file);
        const res = await api.FileManage.UploadPortalFile_PostAsync(data);
        res.forEach((e) => {
          const path = `/files/p/${e.id}/${e.path}`;
          insertFn(e.fileName!, path);
        });
      },

    },
  },
};

const upload = (_: object, file: FormData) => api.FileManage.UploadPortalFile_PostAsync(file);

onBeforeMount(() => {
  ConfigProvider.config({
    theme: {
      primaryColor,
    },
  });
});

useRouteLocal();
dayjs.locale('zh_CN');
const authorImg = computed(() => {
  return {
    authorization: `Bearer ${auth.getToken}`,
  };
});
</script>

<template>
  <div>
    <!-- <Chid /> -->
    <c-config-provider :author-img="authorImg" :locale="zhCN" :modal="{ maskClosable: false }" :editor="editor" :upload="{ api: upload }">
      <div>
        <router-view />
      </div>
    </c-config-provider>
  </div>
</template>

<style>
#app {
  min-width: 1400px;
  min-height: 100%;
  height: fit-content;
  background-color: #f0f2f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 全局设置modal溢出滚动 */
.ant-modal-body {
  overflow: auto;
}

.ant-list-item:hover{
  background-color: #f0f2f5;
}
.ant-list-item{
  padding: 8px;
}
</style>
