/*
 * @Descripttion:
 * @Author: Xinner
 * @Date: 2021-01-20 16:29:57
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-07-07 16:38:04
 */
import type { Router } from 'vue-router';
import { useAuthStore } from '@/stores';

export default (router: Router, whiteListTest: (_path: string) => boolean) => {
  /** 全局路由守卫，处理动态权限 */
  router.beforeEach(async (to, from, next) => {
    const { getToken, refreshToken } = useAuthStore();

    if (getToken || whiteListTest(to.path)) {
      if (getToken && to.name !== 'SilentRenew')
        await refreshToken();

      next();
    }
    else { next(`/login?redirect=${to.path}`); }
  });

  router.afterEach((to) => {
    if (to.meta.title) {
      if (to.meta.title === '首页')
        document.title = `${import.meta.env.VITE_APP_TITLE}`;
      else
        document.title = `${to.meta.title} | ${import.meta.env.VITE_APP_TITLE}`;
    }
  });
};
