<!--
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-09 10:58:57
-->
<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useAppStore } from '../../../stores/modules/app';
import { ContentWidth, Layout, Theme } from '@/types/theme';

const appStore = useAppStore();

const { updateSetting } = appStore;

const appState = computed(() => appStore.$state);

const themeColors = reactive(['#1890ff', '#f5222d', '#fa541c', '#faad14', '#13c2c2', '#2f54eb', '#722ed1']);
</script>

<template>
  <a-drawer v-bind="$attrs" class="setting-theme" title="设置" placement="right">
    <div>
      <h3>整体风格设置</h3>
      <ul class="ant-pro-setting-drawer-block-checbox">
        <li class="ant-pro-setting-drawer-block-checbox-item">
          <img src="./images/light.svg" alt="" @click="updateSetting({ theme: Theme.light })">
          <c-icon-check-outlined v-if="appState.theme === Theme.light" class="check" />
        </li>
        <li class="ant-pro-setting-drawer-block-checbox-item">
          <img src="./images/dark.svg" alt="" @click="updateSetting({ theme: Theme.dark })">
          <c-icon-check-outlined v-if="appState.theme === Theme.dark" class="check" />
        </li>
      </ul>
    </div>

    <div>
      <h3>主题色</h3>
      <ul class="theme-color">
        <li
          v-for="color in themeColors"
          :key="color"
          class="theme-color-block"
          :class="{ 'theme-color-active': color === appState.primaryColor }"
          :style="{ backgroundColor: color }"
          @click="updateSetting({ primaryColor: color })"
        >
          <c-icon-check-outlined v-if="appState.primaryColor === color" />
        </li>
      </ul>
    </div>

    <div>
      <h3>导航模式</h3>
      <a-radio-group v-model:value="appState.layout">
        <a-radio-button :value="Layout.side">
          Side
        </a-radio-button>
        <a-radio-button :value="Layout.top">
          Top
        </a-radio-button>
        <a-radio-button :value="Layout.mix">
          Mix
        </a-radio-button>
      </a-radio-group>
    </div>

    <div class="desc-setting">
      <ul>
        <li>内容区域宽度</li>
        <li>
          <a-select v-model:value="appState.contentWidth" style="width: 100%" :default-value="ContentWidth.fluid">
            <a-select-option :key="ContentWidth.fluid" :value="ContentWidth.fluid">
              流式
            </a-select-option>
            <a-select-option
              v-if="appState.layout !== Layout.side"
              :key="ContentWidth.fixed"
              :value="ContentWidth.fixed"
            >
              定宽
            </a-select-option>
          </a-select>
        </li>
      </ul>
    </div>

    <div class="desc-setting">
      <ul>
        <li>固定 Header</li>
        <li><a-switch v-model:checked="appState.fixedHeader" /></li>
      </ul>
    </div>

    <div class="desc-setting">
      <ul>
        <li>固定侧边菜单</li>
        <li><a-switch v-model:checked="appState.fixSidebar" /></li>
      </ul>
    </div>

    <div class="desc-setting">
      <h3>内容区域</h3>
      <ul>
        <li>顶栏</li>
        <li><a-switch v-model:checked="appState.headerRender" /></li>
      </ul>
    </div>

    <div class="desc-setting">
      <ul>
        <li>页脚</li>
        <li><a-switch v-model:checked="appState.footerRender" /></li>
      </ul>
    </div>

    <div class="desc-setting">
      <ul>
        <li>菜单</li>
        <li><a-switch v-model:checked="appState.menuRender" /></li>
      </ul>
    </div>

    <div class="desc-setting">
      <ul>
        <li>菜单头</li>
        <li><a-switch v-model:checked="appState.menuHeaderRender" /></li>
      </ul>
    </div>
  </a-drawer>
</template>

<style scoped lang="less">
.setting-theme {
  div {
    margin: 16px;
  }
}
ul {
  margin: 0;
  padding: 0;
  display: flex;
  li {
    list-style: none;
  }
}
// .theme-color {
//   ul {
//     li {
//       display: block;
//       width: 20px;
//       height: 20px;
//       border-radius: 4px;
//       margin: 10px;
//     }
//   }
// }

.desc-setting {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.ant-pro-setting-drawer-block-checbox {
  display: flex;
  .ant-pro-setting-drawer-block-checbox-item {
    position: relative;
    margin-right: 10px;
    cursor: pointer;
    .check {
      position: absolute;
      right: 45px;
      bottom: 40px;
      font-weight: bold;
      font-size: 36px;
      color: @primary-color;
    }
  }
}

.theme-color {
  display: flex;
  .theme-color-block {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    font-weight: 700;
    text-align: center;
    border-radius: 2px;
    cursor: pointer;
  }
  .theme-color-active {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
}
</style>
