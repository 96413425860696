/*
 * @Description:
 * @Author: luckymiaow
 * @Date: 2022-05-18 08:54:00
 * @LastEditors: Please set LastEditors
 */
// 白名单，未登录用户可以访问
export const whiteList = [
  /\/answer-system*./,
  '/oidc-callback',
  '/logout-callback',
  '/login',
  '/logout',
  '/register',
  /\/portal\//i,
];
export function whiteListTest(path: string) {
  if (import.meta.env.VITE_APP_MODE === 'Offline')
    return true;
  return whiteList.some((o) => {
    if (o instanceof RegExp)
      return o.test(path);

    return o === path;
  });
}

export function whiteListByCurrentPath() {
  if (location.hash)
    return whiteListTest(location.hash.substring(1).split('?')[0]);
  else
    return whiteListTest(location.pathname);
}
