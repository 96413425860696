<!--
 * @Description: ^_^
 * @Author: sharebravery
 * @Date: 2023-06-08 16:19:35
-->
<script lang="ts" setup>
import { computed, ref } from 'vue';
import { dyRoutes } from '../router/index';
import Icon from '../components/ICON';
import { ContentWidth, Layout, Theme } from '../types/theme';
import Menu from './components/Menu/index.vue';
import Drawer from './components/Drawer/index.vue';
import RightHeader from './components/Header/RightHeader.vue';
import Breadcrumb from './components/Breadcrumb/index.vue';
import { useAppStore } from '@/stores';

const appStore = useAppStore();

const appState = computed(() => appStore.$state);

const menuRoutes = computed(() => {
  return dyRoutes().filter(r => !r.meta?.hidden);
});

const title = import.meta.env.VITE_APP_TITLE as string;

const copyRight = import.meta.env.VITE_APP_COPYRIGHT;

const currentYear = ref(new Date().getFullYear());

const collapsed = ref(false);

const visible = ref(false);

// const getBreadcrumbs = () => {
//   return appState.value.layout !== Layout.top ? ['User', 'Bill'] : [];
// };
</script>

<template>
  <a-layout style="min-height: 100vh">
    <a-layout-header
      v-if="[Layout.top, Layout.mix].includes(appState.layout as Layout) && appState.headerRender"
      class="ch2-header"
      :theme="appState.theme"
      :style="`display: flex; justify-content: space-between; background-color: ${
        appState.theme === Theme.light ? '#ffffff' : '#001529'
      };`"
    >
      <div :class="{ 'flex-layout': appState.layout === Layout.top || appState.layout === Layout.mix }">
        <span v-if="appState.menuHeaderRender" class="title top-nav-title">
          <div class="logo" />
          <span style="color: #4daaff; font-weight: bold; font-size: 20px"> {{ title }} </span>
        </span>
        <Menu
          v-if="appState.layout !== Layout.mix"
          :data="menuRoutes"
          :theme="appState.theme"
          mode="horizontal"
          :style="{ lineHeight: '48px' }"
        />
      </div>
      <RightHeader :theme="appState.theme" />
    </a-layout-header>

    <!-- Side -->
    <template v-else-if="[Layout.side, Layout.mix].includes(appState.layout as Layout) && appState.menuRender">
      <a-layout-sider
        v-model:collapsed="collapsed"
        collapsible
        :theme="appState.theme"
      >
        <div v-if="appState.menuHeaderRender" class="title sider-title justify-center" style="text-align: center">
          <img
            v-show="collapsed" class="logo" src="../assets/action_logo.ico"
          >
          <h3 v-show="!collapsed" style="color: #4daaff; font-size: 18px; font-weight: bold">
            {{ title }}
          </h3>
        </div>
        <Menu :data="menuRoutes" :theme="appState.theme" />
      </a-layout-sider>
    </template>

    <a-layout>
      <a-layout-header
        v-if="appState.layout === Layout.side && appState.headerRender"
        :class="{ 'fixed-header': appState.fixedHeader }"
        style="background: #fff; padding: 0"
      >
        <RightHeader style="float: right" />
      </a-layout-header>

      <template v-else-if="appState.layout === Layout.mix && appState.menuRender">
        <a-layout-sider
          v-model:collapsed="collapsed"
          collapsible
          :theme="appState.layout === Layout.mix ? Theme.light : appState.theme"
        >
          <Menu
            :class="{ 'fixed-header-menu': appState.fixedHeader }"
            :data="menuRoutes"
            :theme="appState.layout === Layout.mix ? Theme.light : appState.theme"
            style="height: 100%"
          />
        </a-layout-sider>
      </template>

      <a-layout-content :class="{ 'fixed-header-content': appState.fixedHeader }" class="content">
        <Breadcrumb />

        <div
          :class="
            [Layout.top, Layout.mix].includes(appState.layout as Layout) && appState.contentWidth !== ContentWidth.fluid
              ? 'content-width-fixed'
              : 'content-width-fluid'
          "
          :style="`margin-top: ${appState.layout === Layout.top ? '20px' : 'auto'};`"
        >
          <router-view />
        </div>
      </a-layout-content>

      <a-layout-footer v-if="appState.layout !== Layout.mix && appState.footerRender" style="text-align: center">
        <div>{{ title }} ©{{ currentYear }}  {{ copyRight }}</div>
        <div>桂ICP备2020007181号-1</div>
      </a-layout-footer>
    </a-layout>

    <a-layout-footer v-if="appState.layout === Layout.mix && appState.footerRender" style="text-align: center">
      <div>{{ title }} ©{{ currentYear }}  {{ copyRight }}</div>
      <div>桂ICP备2020007181号-1</div>
    </a-layout-footer>
  </a-layout>

  <div v-drag class="fixed-button">
    <a-button type="primary" size="large" shape="circle" @click="visible = true">
      <template #icon>
        <Icon icon="setting-outlined " />
      </template>
    </a-button>
  </div>

  <Drawer v-model:visible="visible" />
  <a-back-top :visibility-height="100" />
</template>

<style>
/* scroll bar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #d3d3d3;
  border-radius: 20px;
  box-shadow: inset 0 0 0 white;
}

.fixed-button {
  position: fixed;
  top: 20%;
  right: 56px;
  transform: translateY(-50%);
}

.content {
  margin: 16px;
}

.content-width-fluid {
  padding: 10px;
  min-height: 600px;
}

.content-width-fixed {
  padding: 24px;
  min-height: 600px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
}
.content-width-fluid:has(.bg),.content-width-fixed:has(.bg) {
  background: #fff;

}

.flex-layout {
  display: flex;
  height: 100%;
  padding-left: 16px;
}

.fixed-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.fixed-header-content {
  margin-top: 64px;
}

.fixed-header-menu {
  margin-top: 64px;
}

.fixed-side {
  /* overflow: auto; */
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

.sider-title {
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  transition: padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.top-nav-title {
  position: relative;
  min-width: 165px;
  height: 100%;
  overflow: hidden;
}
/*
.title {
  display: flex;
  align-items: center;
} */

.title > div {
  display: flex;
  align-items: center;
}

.title h3 {
  color: #4daaff;
}

.ant-layout-header {
  padding: 0px;
  height: 48px;
  line-height: 48px;
  width: 100%;
  z-index: 19;
}

/* .ch2-header {
  height: 100%;
} */
</style>

<style lang="less" scoped>
.logo{
  filter: invert(1);
}
</style>
